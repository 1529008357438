import { Link } from "react-router-dom";

const Footer = ()=>{
    return (
        <>

            <footer>
                <h2>The art of Maldives luxury by <img src={require("../../assets/images/logo.png")} alt="footer-heading-logo" /></h2>
                <hr />
                <div className="Footer-Center">
                <div className="footer-icon">
                    <img src={require("../../assets/images/paymentCards.png")} />
                   
                </div> 
                <div className="FooterLinks">
                    <h5>Shadescapes Maldives</h5>
                    <ul>
                    <li><Link to="/about-us">Our Story</Link></li>
                    <li><Link to="/resort-list">Escapes</Link></li>
                    <li><Link to="/contact-us">Connect</Link></li>
                    </ul>
                </div>
                <div className="FooterLinks">
                    <h5>Legal</h5>
                    <ul>
                    <li><Link to="/privacy-policy">Privacy Statement</Link></li>
                    <li><Link to="/terms-conditions">Terms of Service</Link></li>
                    </ul>
                </div>
                </div>
                <div className="footer-bottom">
                <div className="SocialIcons">
                    <ul>
                    {/* <li><Link to="#"><i className="fa-brands fa-twitter"></i></Link></li>
                    <li><Link to="#"><i className="fa-brands fa-facebook"></i></Link></li>
                    <li><Link to="#"><i className="fa-brands fa-instagram"></i></Link></li> */}
                    {/* <li className="footer-icon">
                    <img src={require("../../assets/images/paymentCards.png")} />
                    </li> */}
                    </ul>
                </div>
                <p>&#169; Copyright | Shadescape 2024</p>
                </div>
            </footer>
        
        </>
    );
}

export default Footer;