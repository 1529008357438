import React from 'react'
//import actions from '../../redux/actions';
//import { useSelector } from 'react-redux';
import FooterHeaderWrapper from '../../components/layout/footerHeaderWrapper';
import { Link } from 'react-router-dom';

const Login = () => {
   // const loginRes = useSelector((state) => state);

    // const [data, setData] = useState({
    //     email: 'test1@yopmail.com',
    //     password: 'Test@123'
    // })

    // const login = () => {
    //     actions.loginAction(data);
    // }

    return (
        <FooterHeaderWrapper>
            {/* Login
            <button onClick={login}>Login</button> */}

            <section>
                <div className="hero-section" id="hero-section">
                    {/* <span className="BestStays">#1 Best Stays</span> */}
                    <h2>Where Paradise Casts Its Perfect Shade</h2>
                    <p>Where authentic luxury meets insider knowledge, creating moments that define the true essence of <br/>Maldivian paradise.</p>
                    <div className="HeroProcess">
                        <div className="HeroSmallBox Custom HolidayTypesHover">
                            <div className="form-group">
                                <h6>Experiences</h6>
                                <p>Choose Experience</p>
                            </div>
                            <div className="Overlay"><img src="assets/images/icon-1.png" alt="img" /></div>
                            <div className="HolidayTypesList">
                                <ul>
                                    <li><Link to="#">Honeymoon</Link></li>
                                    <li><Link to="#">Family</Link></li>
                                    <li><Link to="#">Fishing</Link></li>
                                    <li><Link to="#">Diving</Link></li>
                                    <li><Link to="#">Surfing</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="HeroSmallBox Custom CheckInHover">
                            <div className="form-group">
                                <h6>Arrival</h6>
                                <p>Add Date</p>
                            </div>
                            <div className="Overlay"><img src="assets/images/icon-1.png" alt="img" /></div>
                            <div className="CalendarOverlay">
                                <div className="CalendarOverlayAside">
                                    <aside>
                                        <div className="CalendarTitle">
                                            <span className="Left"><i className="fa-solid fa-chevron-left"></i></span>
                                            <h6 className="CalendarTitle">May 2021</h6>
                                        </div>
                                        <ul>
                                            <li><Link to="#">Su</Link></li>
                                            <li><Link to="#">Mo</Link></li>
                                            <li><Link to="#">Tu</Link></li>
                                            <li><Link to="#">We</Link></li>
                                            <li><Link to="#">Th</Link></li>
                                            <li><Link to="#">Fr</Link></li>
                                            <li><Link to="#">Sa</Link></li>
                                            <li><Link to="#">1</Link></li>
                                            <li><Link to="#">2</Link></li>
                                            <li><Link to="#">3</Link></li>
                                            <li><Link to="#">4</Link></li>
                                            <li><Link to="#">5</Link></li>
                                            <li><Link to="#">6</Link></li>
                                            <li><Link to="#">7</Link></li>
                                            <li><Link to="#">8</Link></li>
                                            <li><Link to="#">9</Link></li>
                                            <li><Link to="#">10</Link></li>
                                            <li><Link to="#">11</Link></li>
                                            <li><Link to="#">12</Link></li>
                                            <li><Link to="#">13</Link></li>
                                            <li><Link to="#">14</Link></li>
                                            <li><Link to="#">15</Link></li>
                                            <li><Link to="#">16</Link></li>
                                            <li><Link to="#">17</Link></li>
                                            <li><Link to="#">18</Link></li>
                                            <li><Link to="#">19</Link></li>
                                            <li className="active"><Link to="#">20</Link></li>
                                            <li><Link to="#">21</Link></li>
                                            <li><Link to="#">22</Link></li>
                                            <li><Link to="#">23</Link></li>
                                            <li><Link to="#">24</Link></li>
                                            <li><Link to="#">25</Link></li>
                                            <li><Link to="#">26</Link></li>
                                            <li><Link to="#">27</Link></li>
                                            <li><Link to="#">28</Link></li>
                                            <li><Link to="#">29</Link></li>
                                            <li><Link to="#">30</Link></li>
                                            <li><Link to="#">31</Link></li>
                                        </ul>
                                    </aside>
                                    <aside>
                                        <div className="CalendarTitle">
                                            <span className="Right"><i className="fa-solid fa-chevron-right"></i></span>
                                            <h6 className="CalendarTitle">June 2021</h6>
                                        </div>
                                        <ul>
                                            <li><Link to="#">Su</Link></li>
                                            <li><Link to="#">Mo</Link></li>
                                            <li><Link to="#">Tu</Link></li>
                                            <li><Link to="#">We</Link></li>
                                            <li><Link to="#">Th</Link></li>
                                            <li><Link to="#">Fr</Link></li>
                                            <li><Link to="#">Sa</Link></li>
                                            <li><Link to="#">1</Link></li>
                                            <li><Link to="#">2</Link></li>
                                            <li><Link to="#">3</Link></li>
                                            <li><Link to="#">4</Link></li>
                                            <li><Link to="#">5</Link></li>
                                            <li><Link to="#">6</Link></li>
                                            <li><Link to="#">7</Link></li>
                                            <li><Link to="#">8</Link></li>
                                            <li><Link to="#">9</Link></li>
                                            <li><Link to="#">10</Link></li>
                                            <li><Link to="#">11</Link></li>
                                            <li><Link to="#">12</Link></li>
                                            <li><Link to="#">13</Link></li>
                                            <li><Link to="#">14</Link></li>
                                            <li><Link to="#">15</Link></li>
                                            <li><Link to="#">16</Link></li>
                                            <li><Link to="#">17</Link></li>
                                            <li><Link to="#">18</Link></li>
                                            <li><Link to="#">19</Link></li>
                                            <li className="active"><Link to="#">20</Link></li>
                                            <li><Link to="#">21</Link></li>
                                            <li><Link to="#">22</Link></li>
                                            <li><Link to="#">23</Link></li>
                                            <li><Link to="#">24</Link></li>
                                            <li><Link to="#">25</Link></li>
                                            <li><Link to="#">26</Link></li>
                                            <li><Link to="#">27</Link></li>
                                            <li><Link to="#">28</Link></li>
                                            <li><Link to="#">29</Link></li>
                                            <li><Link to="#">30</Link></li>
                                            <li><Link to="#">31</Link></li>
                                        </ul>
                                    </aside>
                                </div>
                            </div>
                        </div>
                        <div className="HeroSmallBox Custom CheckInHover">
                            <div className="form-group">
                                <h6>Departure</h6>
                                <p>Add Date</p>
                            </div>
                            <div className="Overlay"><img src="assets/images/icon-1.png" alt="img" /></div>
                            <div className="CalendarOverlay">
                                <div className="CalendarOverlayAside">
                                    <aside>
                                        <div className="CalendarTitle">
                                            <span className="Left"><i className="fa-solid fa-chevron-left"></i></span>
                                            <h6 className="CalendarTitle">May 2021</h6>
                                        </div>
                                        <ul>
                                            <li><Link to="#">Su</Link></li>
                                            <li><Link to="#">Mo</Link></li>
                                            <li><Link to="#">Tu</Link></li>
                                            <li><Link to="#">We</Link></li>
                                            <li><Link to="#">Th</Link></li>
                                            <li><Link to="#">Fr</Link></li>
                                            <li><Link to="#">Sa</Link></li>
                                            <li><Link to="#">1</Link></li>
                                            <li><Link to="#">2</Link></li>
                                            <li><Link to="#">3</Link></li>
                                            <li><Link to="#">4</Link></li>
                                            <li><Link to="#">5</Link></li>
                                            <li><Link to="#">6</Link></li>
                                            <li><Link to="#">7</Link></li>
                                            <li><Link to="#">8</Link></li>
                                            <li><Link to="#">9</Link></li>
                                            <li><Link to="#">10</Link></li>
                                            <li><Link to="#">11</Link></li>
                                            <li><Link to="#">12</Link></li>
                                            <li><Link to="#">13</Link></li>
                                            <li><Link to="#">14</Link></li>
                                            <li><Link to="#">15</Link></li>
                                            <li><Link to="#">16</Link></li>
                                            <li><Link to="#">17</Link></li>
                                            <li><Link to="#">18</Link></li>
                                            <li><Link to="#">19</Link></li>
                                            <li className="active"><Link to="#">20</Link></li>
                                            <li><Link to="#">21</Link></li>
                                            <li><Link to="#">22</Link></li>
                                            <li><Link to="#">23</Link></li>
                                            <li><Link to="#">24</Link></li>
                                            <li><Link to="#">25</Link></li>
                                            <li><Link to="#">26</Link></li>
                                            <li><Link to="#">27</Link></li>
                                            <li><Link to="#">28</Link></li>
                                            <li><Link to="#">29</Link></li>
                                            <li><Link to="#">30</Link></li>
                                            <li><Link to="#">31</Link></li>
                                        </ul>
                                    </aside>
                                    <aside>
                                        <div className="CalendarTitle">
                                            <span className="Right"><i className="fa-solid fa-chevron-right"></i></span>
                                            <h6 className="CalendarTitle">June 2021</h6>
                                        </div>
                                        <ul>
                                            <li><Link to="#">Su</Link></li>
                                            <li><Link to="#">Mo</Link></li>
                                            <li><Link to="#">Tu</Link></li>
                                            <li><Link to="#">We</Link></li>
                                            <li><Link to="#">Th</Link></li>
                                            <li><Link to="#">Fr</Link></li>
                                            <li><Link to="#">Sa</Link></li>
                                            <li><Link to="#">1</Link></li>
                                            <li><Link to="#">2</Link></li>
                                            <li><Link to="#">3</Link></li>
                                            <li><Link to="#">4</Link></li>
                                            <li><Link to="#">5</Link></li>
                                            <li><Link to="#">6</Link></li>
                                            <li><Link to="#">7</Link></li>
                                            <li><Link to="#">8</Link></li>
                                            <li><Link to="#">9</Link></li>
                                            <li><Link to="#">10</Link></li>
                                            <li><Link to="#">11</Link></li>
                                            <li><Link to="#">12</Link></li>
                                            <li><Link to="#">13</Link></li>
                                            <li><Link to="#">14</Link></li>
                                            <li><Link to="#">15</Link></li>
                                            <li><Link to="#">16</Link></li>
                                            <li><Link to="#">17</Link></li>
                                            <li><Link to="#">18</Link></li>
                                            <li><Link to="#">19</Link></li>
                                            <li className="active"><Link to="#">20</Link></li>
                                            <li><Link to="#">21</Link></li>
                                            <li><Link to="#">22</Link></li>
                                            <li><Link to="#">23</Link></li>
                                            <li><Link to="#">24</Link></li>
                                            <li><Link to="#">25</Link></li>
                                            <li><Link to="#">26</Link></li>
                                            <li><Link to="#">27</Link></li>
                                            <li><Link to="#">28</Link></li>
                                            <li><Link to="#">29</Link></li>
                                            <li><Link to="#">30</Link></li>
                                            <li><Link to="#">31</Link></li>
                                        </ul>
                                    </aside>
                                </div>
                            </div>
                        </div>
                        <div className="HeroSmallBox Custom RoomsTypeHover">
                            <div className="form-group">
                                <h6>Villas</h6>
                                <p>Villa Count</p>
                            </div>
                            <div className="Overlay"><img src="assets/images/icon-1.png" alt="img" /></div>
                            <div className="RoomsTypesOuter First">
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                    <h6>Villas</h6>
                                    <p>Villa Count</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span><i className="fa-solid fa-minus"></i></span>
                                        <h6>0</h6>
                                        <span><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="HeroSmallBox Custom TravelersHover">
                            <div className="form-group">
                                <h6>Guests</h6>
                                <p>Guest Details</p>
                            </div>
                            <div className="Overlay"><img src="assets/images/icon-1.png" alt="img" /></div>
                            <div className="RoomsTypesOuter Second">
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Adults</h6>
                                        <p>Ages 13 or above</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span><i className="fa-solid fa-minus"></i></span>
                                        <h6>0</h6>
                                        <span><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Childred</h6>
                                        <p>Ages 2 - 12</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span><i className="fa-solid fa-minus"></i></span>
                                        <h6>0</h6>
                                        <span><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>
                                <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Adults</h6>
                                        <p>Under 2</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span><i className="fa-solid fa-minus"></i></span>
                                        <h6>0</h6>
                                        <span><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="form-group mb-0">
                            <Link to="hotel-listing.html" className="SearchBtn"><i className="fa-solid fa-magnifying-glass"></i></Link>
                        </div>
                    </div>
                    <div className="SocialIcons">
                        <ul>
                            <li><Link to="#"><i className="fa-brands fa-instagram"></i></Link></li>
                            <li><Link to="#"><i className="fa-brands fa-twitter"></i></Link></li>
                            <li><Link to="#"><i className="fa-brands fa-youtube"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </section>

            <div id="Main">
                {/* <div className="LearnMoreArea">
                    <p><img src="assets/images/danger.png" alt="img" />Check the latest COVID-19 restrictions before you travel.
                        <span>Learn more</span>
                    </p>
                </div> */}
                <section>
                    <div id="carouselExampleCaptions" className="carousel slide ViewAllPhotos" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="assets/images/banner-1.png" className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <span><img src="assets/images/thumb.png" alt="img" />Best Selling Resort</span>
                                    <h1>Velaa Private Island <br /> Maldives: The Dream Resort</h1>
                                    <p>Trust your vacation to our travel experts and experience the unforgettable sensation <br /> of exploring
                                        the world.
                                    </p>
                                    <span><img src="assets/images/camera-icon.png" alt="img" />View All Photos</span>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="assets/images/banner-1.png" className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <span><img src="assets/images/thumb.png" alt="img" />Best Selling Resort</span>
                                    <h1>Velaa Private Island <br /> Maldives: The Dream Resort</h1>
                                    <p>Trust your vacation to our travel experts and experience the unforgettable sensation <br /> of exploring
                                        the world.
                                    </p>
                                    <span><img src="assets/images/camera-icon.png" alt="img" />View All Photos</span>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="assets/images/banner-1.png" className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <span><img src="assets/images/thumb.png" alt="img" />Best Selling Resort</span>
                                    <h1>Velaa Private Island <br /> Maldives: The Dream Resort</h1>
                                    <p>Trust your vacation to our travel experts and experience the unforgettable sensation <br /> of exploring
                                        the world.
                                    </p>
                                    <span><img src="assets/images/camera-icon.png" alt="img" />View All Photos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="BlogsArea PackagesFourBoxs">
                        <div className="header-title">
                            <h2> <img src="assets/images/blog-heading.png" alt="img" /> THE SHADESCAPES EDIT <img src="assets/images/blog-animation.gif" className="blog-animation" alt="img" /> </h2>
                            {/* <p>Plan and book our perfect trip with expert advice, travel tips, destination information and <br />  inspiration from us</p> */}
                        </div>

                        <div className="owl-carousel owl-theme">

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-1.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-2.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-3.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-4.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-1.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-2.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-3.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <Link to="property-details-booking.html">
                                <div className="item">
                                    <div className="PackagesBox">
                                        <div className="Package-img">
                                            <img src="assets/images/package/package-4.png" alt="img" />
                                            <div className="Overlay">
                                                <h6>3N4D</h6>
                                            </div>
                                        </div>
                                        <h3>Soneva Jani Resort</h3>
                                        <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                        <div className="price-box">
                                            <h5 className="Price">$130</h5>
                                            <h5 className="Discount">$150</h5>
                                            <h5 className="Percentage">30% <small>off</small></h5>
                                        </div>
                                        <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                    </div>
                                </div>
                            </Link>

                            <div className="item">
                                <div className="PackagesBox">
                                    <div className="Package-img">
                                        <img src="assets/images/package/package-4.png" alt="img" />
                                        <div className="Overlay">
                                            <h6>3N4D</h6>
                                        </div>
                                        <Link to="property-details-booking.html" className="ExploreAllOverlay">Explore All</Link>
                                    </div>
                                    <h3>Soneva Jani Resort</h3>
                                    <p><i className="fa-solid fa-location-dot"></i>Lorem ipsum road, Tantruim-2322, Melbourne, Australia</p>
                                    <div className="price-box">
                                        <h5 className="Price">$130</h5>
                                        <h5 className="Discount">$150</h5>
                                        <h5 className="Percentage">30% <small>off</small></h5>
                                    </div>
                                    <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



            </div>
        </FooterHeaderWrapper>
    )
}

export default Login