import { Link } from "react-router-dom";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

const ResortBookingConfirmed = () => {
    let { booking_id } = useParams();

    let resort_booking_details = useSelector((state) => state.booking.getResortBookingDetails);

    useEffect(() => {
        actions.getResortBookingDetailsAction(booking_id);
    }, []);
    console.log('resort booking details ---- ', resort_booking_details);

    return (
        <>
            <div className="DetailsGrey">

                <div className="HotelBreadcumb">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/booking-list">Booking List</Link></li>
                        <li className="active">Booking Details</li>
                    </ul>
                </div>


                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="BookingConfirmHeading">
                            <div className="d-flex align-items-center">
                                <h4><span><img src={require("../../assets/images/confirm.png")} alt="confirm" /></span>Booking Confirmed</h4>
                                <p className="ml-5">Booking ID: <span>#RT213Q</span></p>
                            </div>
                            <a href="javascript:void(0);" className="BrandBtn">Invoice</a>
                        </div>
                        {
                            resort_booking_details?.resort_room_details?.map((val, index) => {
                                return (
                                    <>
                                        <div key={index} className="SingleRoomBox">
                                            <figure><img src={val?.images ? val?.images : require("../../assets/images/room-1.png")} alt="confirm" /></figure>
                                            <div className="SingleRoomRight">
                                                <h5>{val?.room_title}</h5>
                                                <ul className="First">
                                                    <li><span><img src={require("../../assets/images/sq-ft.png")} alt="confirm" /></span>300 sq ft</li>
                                                    <li><span><img src={require("../../assets/images/sq-ft.png")} alt="confirm" /></span>King Size Bed</li>
                                                    <li><span><img src={require("../../assets/images/sq-ft.png")} alt="confirm" /></span>City View</li>
                                                </ul>
                                                <ul className="Second">
                                                    <li><i className="fa-solid fa-check"></i>Bed and Breakfast</li>
                                                    <li><i className="fa-solid fa-check"></i>Express check-in, Free WiFi, Free fitness center access</li>
                                                </ul>
                                                <p><span><img src={require("../../assets/images/icon-3.png")} alt="confirm" /></span>2 adult X 1 room</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        
                        <div className="AmenitiesBox">
                            <h4>Amenities</h4>
                            <ul>
                                <li>Breakfast</li>
                                <li>Room sanitizing</li>
                                <li>Outdoor gym access</li>
                                <li>Breakfast for 2 people</li>
                                <li>Protective masks</li>
                                <li>Free fitness center access</li>
                                <li>Limited Laundry service</li>
                                <li>Medical staff on call</li>
                                <li>Cable TV channels</li>
                                <li>Bike rental</li>
                                <li>Free Premium Wifi</li>
                                <li>Smart TV (with apps)</li>
                                <li>Complimentary in-room fruit</li>
                                <li>Free Wifi</li>
                                <li>Express check-in</li>
                                <li>Welcome drink</li>
                                <li>Drinking water</li>
                                <li>Hand sanitizer </li>
                                <li>Coffee & tea</li>
                                <li>Indoor gym access</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="ArrivalTransferType">
                                    <h4>Arrival Transfer Type</h4>
                                    <span className="Carbon"><img src={require("../../assets/images/carbon_information.png")} alt="confirm" /></span>
                                    <ul>
                                        <li>
                                            <p><i className="fa-solid fa-check"></i>Sea Plane</p>
                                            <h6>$13</h6>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="ArrivalTransferType">
                                    <h4>Domestic Transfer Type</h4>
                                    <span className="Carbon"><img src={require("../../assets/images/carbon_information.png")} alt="confirm" /></span>
                                    <ul>
                                        <li>
                                            <p><i className="fa-solid fa-check"></i>Sea Boat</p>
                                            <h6>$13</h6>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="SpecialRequest">
                            <h4>Special Request</h4>
                            <div className="SpecialContent">
                                <p>I’d like to have a baby cot (additional charges may apply)</p>
                            </div>
                        </div>
                        <div className="CustomerDetails">
                            <h4>Customer Details</h4>
                            <div className="CustomerContent Filled">
                                <form>
                                    <div className="form-group">
                                        <h6>Name</h6>
                                        <p>Nico Alverez</p>
                                    </div>
                                    <div className="form-group">
                                        <h6>Name</h6>
                                        <p>Nico Alverez</p>
                                    </div>
                                    <div className="form-group">
                                        <h6>Name</h6>
                                        <p>Nico Alverez</p>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div className="MultiFacilities" id="Policies">
                            <div className="PolicyGreyBox">
                                <h4>Policies</h4>
                            </div>
                            <div className="PoliciesBox Common">
                                <div className="d-flex">
                                    <aside>
                                        <p>Bookings at this property are non-refundable.</p>

                                        <p className="mt-4">Learn more about our <a href="javascript:void(0);">Cancellation policies <span><img src={require("../../assets/images/carbon_information.png")} alt="img" /></span></a></p>
                                    </aside>
                                    <aside>
                                        <p>If you have upcoming trips, you can manage or <br /> cancel your booking in your traveler account.</p>
                                    </aside>
                                </div>
                            </div>
                            <div className="CheckInCheckOut Common">
                                <h5>Check-in/Check-out</h5>
                                <ul>
                                    <li><span><img src={require("../../assets/images/check-in.png")} alt="confirm" /></span>Check in after: 4:00 PM</li>
                                    <li><span><img src={require("../../assets/images/check-out.png")} alt="confirm" /></span>Check out before: 9:00 AM</li>
                                </ul>
                            </div>
                            <div className="DamageIncidentals Common">
                                <h5>Damage and Incidentals</h5>
                                <ul>
                                    <li>You will be responsible for any damage to the rental property caused by you or your party during your stay.</li>
                                </ul>
                            </div>
                            <div className="HouseRules Common">
                                <h5>House Rules</h5>
                                <ul>
                                    <li>Children allowed No Pets.</li>
                                    <li>No Events</li>
                                    <li>Max Guess:12</li>
                                    <li>No Pets</li>
                                    <li>No Smoking</li>
                                    <li>Minimum Age of Primary Renter: 25</li>
                                </ul>
                            </div>
                            <div className="GettingAround Common">
                                <aside>
                                    <h5>Getting around</h5>
                                    <ul>
                                        <li>Airport transfer fee: 1100 USD</li>
                                        <li>Travel time to airport (minutes): 10</li>
                                    </ul>
                                </aside>
                                <aside>
                                    <h5>Extra</h5>
                                    <ul>
                                        <li>
                                            Breakfast charge (unless included in room price): 80 USD
                                        </li>
                                        <li>Daily Internet/Wi-Fi fee: 0 USD</li>
                                    </ul>
                                </aside>
                            </div>
                            <div className="DamageIncidentals Common">
                                <h5>Others</h5>
                                <ul>
                                    <li>Additional child rates are applicable to children 7-14 years old.</li>
                                    <li>Children 15 years and over are subject to additional adult rates.</li>
                                    <li>The additional charges are applicable to all services including meals and air transfer fees.</li>
                                    <li>When booking more than 5 rooms, different policies and additional supplements may apply.</li>
                                </ul>
                            </div>
                            <div className="DamageIncidentals Common">
                                <h5>The Property</h5>
                                <ul>
                                    <li>Number of bars/lounges: 2</li>
                                    <li>Number of restaurants: 3</li>
                                    <li>Number of rooms : 54</li>
                                    <li>Room voltage: 220</li>
                                    <li>Year property opened: 2016</li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="NonRefundableBox">
                            <figure><img style={{minWidth: '100%'}} src={resort_booking_details?.resort_details?.images ? resort_booking_details?.resort_details?.images : require("../../assets/images/img-11.png")} alt="confirm" /></figure>
                            <figcaption>
                                <h1>{resort_booking_details?.resort_details?.resort_name}</h1>
                                <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                <p><i className="fa-solid fa-location-dot"></i>{resort_booking_details?.resort_details?.resort_address}, {resort_booking_details?.resort_details?.resort_pincode}</p>
                                <div className="Cancellation">
                                    <label>Non refundable</label>
                                    <span><img src={require("../../assets/images/carbon_information.png")} alt="confirm" />Cancellation Policy</span>
                                </div>
                                <label>Check in: {moment(resort_booking_details?.data?.booking_start_date).format('dddd, MMMM D, YYYY')}</label>
                                <label>Check out: {moment(resort_booking_details?.data?.booking_end_date).format('dddd, MMMM D, YYYY')}</label>
                                <label>{moment(resort_booking_details?.data?.booking_end_date).diff(moment(resort_booking_details?.data?.booking_start_date), 'days')} night stay</label>
                            </figcaption>
                        </div>
                        <div className="PriceDetailsBox">
                            <h4>Price Details</h4>
                            <div className="PriceDetailsContent">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2 rooms X 2 nights</td>
                                            <td>$ 120.32</td>
                                        </tr>
                                        <tr>
                                            <td>Discount</td>
                                            <td>-$ 12.36</td>
                                        </tr>
                                        <tr>
                                            <td>Tax and service fees</td>
                                            <td>$ 8.32</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total</td>
                                            <td>$130</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div className="p-3">
                                    <button className="PayBtn" data-toggle="modal" data-target="#BookingConfirmedModal">Cancel Booking</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>





                {/* <div className="PinkWarning mt-5">
                    <p><img src={require("../../assets/images/danger.png")} alt="confirm"/>Check the latest COVID-19 restrictions before you travel. <span>Learn more</span></p>
                </div> */}

            </div>
        </>
    )
}

export default ResortBookingConfirmed;