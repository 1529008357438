export function formatDateWithAmPm(dateString) {
    // Parse the ISO 8601 date string to a Date object
    const date = new Date(dateString);

    // Get hours, minutes, and seconds
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format minutes and seconds to always be two digits
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const secondsStr = seconds < 10 ? '0' + seconds : seconds;

    // Return the formatted date string with AM/PM
    return `${hours}:${minutesStr} ${ampm}`;
}