import { useLocation, useParams } from "react-router-dom";
import FooterHeaderWrapper from "../layout/footerHeaderWrapper";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ZohoChatSupport from "../chat";
import GoogleMapReact from "google-map-react";
import DatePicker from "react-datepicker";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";

const PackageDetails = () => {
  let user_details = JSON.parse(localStorage.getItem("user_details"));

  let { package_id } = useParams();
  console.log(package_id);
  const uniqueAmenities = new Set();
  const Navigate = useNavigate();
  const location = useLocation();
  const CloneformData = location?.state?.formData;
  const filterData = location?.state?.filterData;

  //const [addMoreList, setAddMoreList] = useState(false);
  const [countList, setCountList] = useState([]);
  const [activeTab, setActiveTab] = useState("Overview");

  const options = {
    items: 1,
    // nav: false,
    // navText: ["<i class='fas fa-angle-left' style={{color:'black'}}></i>", "<i class='fas fa-angle-right'></i>"],
    rewind: true,
    autoplay: true,
    loop: true,
  };

  //Calculate Amount
  const [totalAmount, setTotalAmount] = useState({
    totalResult: "",
    totalDiscount: "",
  });

  //search data
  const today = new Date();
  const currentDate = moment(today).format("YYYY-MM-DD");
  const nextDate = moment(currentDate, "YYYY-MM-DD")
    .add(2, "days")
    .format("YYYY-MM-DD");

  const [formData, setFormData] = useState({
    checkInDate: currentDate,
    checkOutDate: nextDate,
    rooms: 1,
    adults: 2,
    childrens: 0,
    infant: 0,
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const [showCheckOutCalander, setShowCheckOutCalander] = useState(false);

  let packageListDetails = useSelector(
    (state) => state.packageReducer.packageListDetails
  );
  console.log("package list details --- ", packageListDetails);
  useEffect(() => {
    actions.getPackageListDetailsAction(package_id);
  }, [package_id]);

  const getBestPrice = () => {
    toast.warning("Login to get best price");
  };

  const handleCountData = (e, id) => {
    setCountList([...countList, { count: 1, id }]);
  };

  const handleCountPlus = (id) => {
    setCountList(
      countList
        .map((data) => {
          if (data.id === id) {
            const newCount = (data.count || 0) + 1;
            if (newCount <= 0) {
              return null;
            } else {
              return { ...data, count: newCount };
            }
          }
          return data;
        })
        .filter(Boolean)
    );
  };

  const handleCountMinus = (id) => {
    setCountList(
      countList
        .map((data) => {
          if (data.id === id) {
            const newCount = (data.count || 0) - 1;
            if (newCount <= 0) {
              return null;
            } else {
              return { ...data, count: newCount };
            }
          }
          return data;
        })
        .filter(Boolean)
    );
  };

  const handleReserve = () => {
    Navigate(`/booking-details`, {
      state: {
        reserveData: countList,
        package_id: package_id,
        searchData: formData,
      },
    });
  };

  const scrollToTab = (id) => {
    setActiveTab(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  //Calculate Amount
  useEffect(() => {
    {
      let totalResult = [];
      let totalDiscount = [];
      packageListDetails?.package_pricing?.map((val, index) =>
        val?.package_pricing_data_range?.map((value, index) =>
          totalResult.push(parseFloat(value?.base_price_per_night))
        )
      );
      packageListDetails?.package_pricing?.map((val, index) =>
        val?.package_pricing_data_range?.map((value, index) =>
          totalDiscount.push(
            parseFloat(value?.base_price_per_night) -
              parseFloat(value?.base_discounted_price)
          )
        )
      );
      setTotalAmount({ ...totalAmount, totalResult, totalDiscount });
    }
  }, [packageListDetails]);

  //map
  const [defaultProps, setDefaultProps] = useState(null);
  const lat = packageListDetails?.package_details?.latitude;
  const lng = packageListDetails?.package_details?.longitude;

  useEffect(() => {
    if (lat && lng) {
      setDefaultProps({
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        zoom: 11,
      });
    }
  }, [lat, lng]);

  const Marker = ({ text, icon }) => (
    <div
      style={{
        position: "relative",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img src={icon} alt={text} style={{ width: "50px", height: "50px" }} />
      <div
        style={{
          position: "absolute",
          bottom: "-25px",
          left: "50%",
          transform: "translateX(-50%)",
          color: "black",
          background: "white",
          padding: "2px 5px",
          borderRadius: "3px",
          fontSize: "12px",
        }}
      >
        {text}
      </div>
    </div>
  );

  //calander and search data on hover
  const handleMouseEnter = () => {
    setShowCalendar(true);
  };
  const handleMouseLeave = () => {
    setShowCalendar(false);
  };

  const handleMouseEnterCheckOut = () => {
    setShowCheckOutCalander(true);
  };
  const handleMouseLeaveCheckOut = () => {
    setShowCheckOutCalander(false);
  };

  const handleIncrement = () => {
    setFormData((prevState) => ({ ...prevState, rooms: prevState?.rooms + 1 }));
  };
  const handleDecrement = () => {
    if (formData?.rooms > 0) {
      setFormData((prevState) => ({
        ...prevState,
        rooms: prevState?.rooms - 1,
      }));
    }
  };

  const handleAdultIncrement = () => {
    setFormData((prevState) => ({
      ...prevState,
      adults: prevState?.adults + 1,
    }));
  };
  const handleAdultDecrement = () => {
    if (formData?.adults > 0) {
      setFormData((prevState) => ({
        ...prevState,
        adults: prevState?.adults - 1,
      }));
    }
  };

  const handleChildrenIncrement = () => {
    setFormData((prevState) => ({
      ...prevState,
      childrens: prevState?.childrens + 1,
    }));
  };
  const handleChildrenDecrement = () => {
    if (formData?.childrens > 0) {
      setFormData((prevState) => ({
        ...prevState,
        childrens: prevState?.childrens - 1,
      }));
    }
  };

  const handleInfantIncrement = () => {
    setFormData((prevState) => ({
      ...prevState,
      infant: prevState?.infant + 1,
    }));
  };
  const handleInfantDecrement = () => {
    if (formData?.infant > 0) {
      setFormData((prevState) => ({
        ...prevState,
        infant: prevState?.infant - 1,
      }));
    }
  };

  useEffect(() => {
    if (location?.state) {
      setFormData({
        ...formData,
        checkInDate: filterData?.checkInDate,
        checkOutDate: filterData?.checkOutDate,
        rooms: CloneformData?.rooms,
        adults: CloneformData?.adults,
        childrens: CloneformData?.childrens,
        infant: CloneformData?.infant,
      });
    }
  }, [location?.state]);

  return (
    <FooterHeaderWrapper>
      <div className="HotelBreadcumb Position">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/packages">Packages</Link>
          </li>
          {/* <li><Link to="/">Family Package</Link></li> */}
          <li className="active">Package Details</li>
        </ul>
      </div>
      <div className="IndividualHotelDetail">
        <div>
          {packageListDetails?.package_basic_info?.map((data, index) => (
            <h1 key={index}>{data?.package_name}</h1>
          ))}
          <h6>
            <span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p>
          </h6>
          <p>
            <i className="fa-solid fa-location-dot"></i>
            {packageListDetails?.package_details?.resort_address} ,{" "}
            {packageListDetails?.package_details?.resort_pincode}
          </p>
        </div>
        <div>
          {/* <span className="Maps" id="chatSupport"><img src={require("../../assets/images/chat-support.png")} alt="chat" />Chat Support</span> */}
          <ZohoChatSupport />
        </div>
      </div>
      <div className="IndividualHotelImages">
        <div className="row">
          <div className="col-md-6">
            <figure>
              <img
                src={
                  packageListDetails?.package_details?.images
                    ? packageListDetails?.package_details?.images
                    : require("../../assets/images/select-1.png")
                }
                alt="package-details"
                width="100%"
              />
            </figure>
          </div>
          <div className=" col-md-6">
            <div className="row">
              {packageListDetails?.package_basic_info?.map((val) => {
                return val?.images?.map((imageUrl, index) => (
                  <>
                    <div className=" col-md-6" key={index}>
                      <figure>
                        <img
                          src={
                            imageUrl
                              ? imageUrl
                              : require("../../assets/images/select-2.png")
                          }
                          alt="package-details-1"
                          width="100%"
                          height="100%"
                        />
                      </figure>
                    </div>
                  </>
                ));
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="FacilitiesTabs">
        <ul>
          <li>
            <a
              href="#Overview"
              className={activeTab === "Overview" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Overview");
              }}
            >
              Overview
            </a>
          </li>
          <li>
            <a
              href="#Highlights"
              className={activeTab === "Highlights" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Highlights");
              }}
            >
              Highlights
            </a>
          </li>
          <li>
            <a
              href="#Facilities"
              className={activeTab === "Facilities" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Facilities");
              }}
            >
              Facilities
            </a>
          </li>
          <li>
            <a
              href="#Rooms"
              className={activeTab === "Rooms" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Rooms");
              }}
            >
              Rooms
            </a>
          </li>
          <li>
            <a
              href="#Location"
              className={activeTab === "Location" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Location");
              }}
            >
              Location
            </a>
          </li>
          <li>
            <a
              href="#Policies"
              className={activeTab === "Policies" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Policies");
              }}
            >
              Policies
            </a>
          </li>
          <li>
            <a
              href="#Review&Rating"
              className={activeTab === "Review&Rating" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Review&Rating");
              }}
            >
              Review & Rating
            </a>
          </li>
        </ul>
      </div>

      <div className="DetailsGrey">
        <div className="OverViewBox TabTarget" id="Overview">
          <h4>Overview</h4>
          <p>
            {packageListDetails?.package_basic_info?.map((val, index) => {
              return (
                <>
                  <p key={index}>
                    <div
                      dangerouslySetInnerHTML={{ __html: val?.about_package }}
                    />
                    {/* {val?.about_package} */}
                  </p>
                </>
              );
            })}
          </p>
        </div>

        <div className="HighlightsBox TabTarget" id="Highlights">
          <h4>Highlights</h4>
          <ul>
            <li>
              <span>
                <img
                  alt="wifi"
                  src={require("../../assets/images/home-wifi.png")}
                />
              </span>
              Free wifi
            </li>
            <li>
              <span>
                <img
                  alt="business-service"
                  src={require("../../assets/images/business-service.png")}
                />
              </span>
              Business Services
            </li>
            <li>
              <span>
                <img
                  alt="air-condition"
                  src={require("../../assets/images/air-conditioning.png")}
                />
              </span>
              Air Conditioning
            </li>
            <li>
              <span>
                <img
                  alt="swimming"
                  src={require("../../assets/images/swimming.png")}
                />
              </span>
              Swimming Pool
            </li>
            <li>
              <span>
                <img
                  alt="airport"
                  src={require("../../assets/images/airport.png")}
                />
              </span>
              Airport Transfer
            </li>
            <li>
              <span>
                <img
                  alt="top-rated"
                  src={require("../../assets/images/top-rated.png")}
                />
              </span>
              Top rated in area
            </li>
            <li>
              <span>
                <img
                  alt="top-rated"
                  src={require("../../assets/images/top-rated.png")}
                />
              </span>
              Top rated in area
            </li>
            <li>
              <span>
                <img
                  alt="top-rated"
                  src={require("../../assets/images/top-rated.png")}
                />
              </span>
              Top rated in area
            </li>
          </ul>
        </div>

        <div className="FacilitiesBox TabTarget" id="Facilities">
          <h4>Facilities</h4>
          <ul>
            {packageListDetails?.package_amenities?.forEach((val) => {
              val?.amenities?.split(", ")?.forEach((item) => {
                const trimmedItem = item?.trim();
                uniqueAmenities.add(trimmedItem);
              });
            })}
            {[...uniqueAmenities]?.map((uniqueItem, index) => (
              <li key={index}>{uniqueItem}</li>
            ))}
          </ul>
        </div>

        <div className="AvailableRoomsArea TabTarget" id="Rooms">
          <h2>Available Rooms</h2>

          <div className="CheckAvailability">
            <div className="form-group NewGreyBox HeroSmallBox HeroSmallBoxDate">
              <div className="Overlay">
                <span>
                  <img
                    alt="date"
                    src={require("../../assets/images/date-icon.png")}
                  />
                </span>
              </div>
              {/* <input type="text"  /> */}
              <div
                className="hover-datepicker"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <DatePicker
                  width="100%"
                  // minDate={today}
                  selected={formData?.checkInDate}
                  onChange={(date) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      checkInDate: date,
                    }))
                  }
                  open={showCalendar}
                  onFocus={() => setShowCalendar(true)}
                  onBlur={() => setShowCalendar(false)}
                  dateFormat="MMMM dd, yyyy"
                  placeholderText="Month DD, YYYY"
                  name="checkInDate"
                  monthsShown={2}
                  selectsStart
                  // selectsEnd
                  // selectsRange={true}
                  // onChange={(update) => {
                  //     setFormData((prevState) => ({ ...prevState, checkInDate: update }))
                  //   }}
                  startDate={formData?.checkInDate}
                  endDate={formData?.checkOutDate}
                />
              </div>
            </div>
            <div className="form-group NewGreyBox HeroSmallBox HeroSmallBoxDate">
              <div className="Overlay">
                <span>
                  <img
                    alt="date"
                    src={require("../../assets/images/date-icon.png")}
                  />
                </span>
              </div>

              <div
                className="hover-datepicker"
                onMouseEnter={handleMouseEnterCheckOut}
                onMouseLeave={handleMouseLeaveCheckOut}
              >
                <DatePicker
                  width="100%"
                  open={showCheckOutCalander}
                  onFocus={() => setShowCheckOutCalander(true)}
                  onBlur={() => setShowCheckOutCalander(false)}
                  dateFormat="MMMM dd, yyyy"
                  placeholderText="Month DD, YYYY"
                  className="form-control"
                  name="checkOutDate"
                  selected={formData?.checkOutDate}
                  onChange={(date) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      checkOutDate: date,
                    }))
                  }
                  minDate={formData?.checkInDate}
                  selectsEnd
                  startDate={formData?.checkInDate}
                  endDate={formData?.checkOutDate}
                  monthsShown={2}
                />
              </div>
            </div>

            <div className="form-group Custom RoomsTypeHover">
              <p>Rooms ({formData?.rooms})</p>
              <div className="Overlay">
                <span>
                  <img
                    alt="overlay"
                    src={require("../../assets/images/icon-2.png")}
                  />
                </span>
              </div>
              <div className="RoomsTypesOuter First CheckAvailabilityShow">
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Rooms</h6>
                    <p>Number of Rooms</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>{formData?.rooms}</h6>
                    <input type="hidden" name="rooms" value={formData?.rooms} />
                    <span onClick={handleIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group Custom TravelersHover">
              <p>
                Add Guests ({formData?.adults} + {formData?.childrens} +{" "}
                {formData?.infant})
              </p>
              <div className="Overlay">
                <span>
                  <img
                    alt="icon-3"
                    src={require("../../assets/images/icon-3.png")}
                  />
                </span>
              </div>
              <div className="RoomsTypesOuter Second CheckAvailabilityShow">
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Adults</h6>
                    <p>Ages 13 or above</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleAdultDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>
                      {formData?.adults}{" "}
                      <input
                        type="hidden"
                        name="adults"
                        value={formData?.adults}
                      />
                    </h6>
                    <span onClick={handleAdultIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Childred</h6>
                    <p>Ages 2 - 12</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleChildrenDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>
                      {formData?.childrens}{" "}
                      <input
                        type="hidden"
                        name="childrens"
                        value={formData?.childrens}
                      />
                    </h6>
                    <span onClick={handleChildrenIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Infant</h6>
                    <p>Ages 0 - 2</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleInfantDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>
                      {formData?.infant}{" "}
                      <input
                        type="hidden"
                        name="infant"
                        value={formData?.infant}
                      />
                    </h6>
                    <span onClick={handleInfantIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
                {/* <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Adults</h6>
                                        <p>Under 2</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span><i className="fa-solid fa-minus"></i></span>
                                        <h6>0</h6>
                                        <span><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="form-group">
              <Link to="#" className="CheckAvailabilityBtn">
                Check Availability
              </Link>
            </div>
          </div>

          {packageListDetails?.package_room_types?.map((val, index) => {
            return (
              <>
                <div className="CheckOutRoom" key={index}>
                  <div className="CheckOutRoomSlider">
                    <OwlCarousel options={options}>
                      {val?.images?.length > 1
                        ? val?.images?.map((img, i) => {
                            return (
                              <div key={i}>
                                <figure>
                                  <img src={img} />
                                </figure>
                              </div>
                            );
                          })
                        : ""}
                    </OwlCarousel>
                  </div>

                  <div className="CheckOutContentMain">
                    <div className="CheckOutContent">
                      <div className="CheckOutLeft">
                        <h5>{val?.room_type1}</h5>
                        <ul className="First">
                          <li>
                            <span>
                              <img
                                alt="sq-ft"
                                src={require("../../assets/images/sq-ft.png")}
                              />
                            </span>
                            {val?.room_size_value ? val?.room_size_value : "30"}{" "}
                            {val?.room_size_unit === "sq_feet"
                              ? "sq ft"
                              : "sq mtr"}
                          </li>
                          <li>
                            <span>
                              <img
                                alt="sq-ft"
                                src={require("../../assets/images/sq-ft.png")}
                              />
                            </span>
                            {val?.bed_size}
                          </li>
                          <li>
                            <span>
                              <img
                                alt="sq-ft"
                                src={require("../../assets/images/sq-ft.png")}
                              />
                            </span>
                            City View
                          </li>
                        </ul>
                        <ul className="Second">
                          <li>
                            {packageListDetails?.package_meal_supplement?.map(
                              (data, index) => (
                                <>
                                  <i
                                    className="fa-solid fa-check"
                                    key={index}
                                  ></i>
                                  {data?.meal_plan}
                                </>
                              )
                            )}
                          </li>
                          <li>
                            {packageListDetails?.unique_amenities?.map(
                              (data, index) => (
                                <>
                                  <i
                                    className="fa-solid fa-check"
                                    key={index}
                                  ></i>
                                  {data}
                                </>
                              )
                            )}
                          </li>
                        </ul>
                        <Link to="#">
                          <span>
                            <img
                              alt="carbon-information"
                              src={require("../../assets/images/carbon_information.png")}
                            />
                          </span>
                          Cancellation Policy
                        </Link>
                      </div>
                      <div className="CheckOutRight">
                        <div className="CheckOutRightTop">
                          {user_details ? (
                            <h4>
                              <span>${totalAmount?.totalResult[index]}</span>$
                              {totalAmount?.totalDiscount[index]}
                            </h4>
                          ) : (
                            <h4>${totalAmount?.totalResult[index]}</h4>
                          )}

                          <p>Includes taxes and fees</p>
                        </div>
                        <div className="CheckOutRightBottom">
                          <Link
                            to="#"
                            onClick={(e) => handleCountData(e, val?.id)}
                            className="BookBtn SelectRoomBtn"
                          >
                            Select Room
                          </Link>
                          {countList.some((item) => item.id === val.id) && (
                            <div
                              className="PlusMinusBoxMain"
                              style={{ display: "block" }}
                              key={index}
                            >
                              <div className="PlusMinusBox">
                                <span onClick={() => handleCountMinus(val.id)}>
                                  <i className="fa-solid fa-minus"></i>
                                </span>
                                <h6>
                                  {countList.find((item) => item.id === val.id)
                                    ?.count || 0}{" "}
                                  Room
                                </h6>
                                <span onClick={() => handleCountPlus(val.id)}>
                                  {" "}
                                  <i className="fa-solid fa-plus"></i>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {user_details ? (
                          ""
                        ) : (
                          <Link
                            to="#"
                            onClick={getBestPrice}
                            className="LoginLink"
                          >
                            Login to unlock the best price
                          </Link>
                        )}
                      </div>
                    </div>
                    {/* <div className="CheckOutContent" style={{ border: 'none' }}>
                                                <div className="CheckOutLeft">
                                                    <ul className="Second">
                                                        <li><i className="fa-solid fa-check"></i>Bed and Breakfast</li>
                                                        <li><i className="fa-solid fa-check"></i>Express check-in, Free WiFi, Free fitness center access</li>
                                                        <li><i className="fa-solid fa-check"></i>Lunch and Dinner</li>
                                                    </ul>
                                                    <Link to="#"><span><img alt="carbon" src={require("../../assets/images/carbon_information.png")} /></span>Cancellation Policy</Link>
                                                    <div className="CheckOutBottom">
                                                        <p><span><img alt="icon-3" src={require("../../assets/images/icon-3.png")} /></span>{val?.adults} adult, {val?.children} children (Below 12 years)</p>
                                                    </div>
                                                </div>
                                                <div className="CheckOutRight">
                                                    <div className="CheckOutRightTop">
                                                        {
                                                            (() => {
                                                                let totalResult = [];
                                                                let totalDiscounted = [];
                                                                packageListDetails?.package_pricing?.map((val, index) => (
                                                                    val?.package_pricing_data_range?.map((value, index) => (
                                                                        totalResult.push(parseFloat(value?.base_price_per_night))
                                                                    ))
                                                                ));
                                                                packageListDetails?.package_pricing?.map((val, index) => (
                                                                    val?.package_pricing_data_range?.map((value, index) => (
                                                                        totalDiscounted.push(parseFloat(value?.base_price_per_night) - parseFloat(value?.base_discounted_price))
                                                                    ))
                                                                ));
                                                                return (
                                                                    <h4><span>${totalResult[index]}</span>${totalDiscounted[index]}</h4>
                                                                );
                                                            })()
                                                        }
                                                        <p>Includes taxes and fees</p>
                                                    </div>
                                                    <div className="CheckOutRightBottom">
                                                        <Link to="#" className="BookBtn SelectRoomBtn">Select Room</Link>
                                                        <div className="PlusMinusBoxMain">
                                                            <div className="PlusMinusBox">
                                                                <span><i className="fa-solid fa-minus"></i></span>
                                                                <h6>1 Room</h6>
                                                                <span><i className="fa-solid fa-plus"></i></span>
                                                            </div>
                                                        </div>
                                                        {user_details ?
                                                            ""
                                                            :
                                                            <Link to="#" onClick={getBestPrice} className="LoginLink">Login to unlock the best price</Link>
                                                        }

                                                    </div>
                                                </div>
                                            </div> */}
                  </div>
                </div>
                {countList?.length > 0 && (
                  <div
                    className="ReserveBox"
                    onClick={handleReserve}
                    style={{
                      display: "block",
                      padding: "10px",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <span>I'll Reserve</span>
                  </div>
                )}
              </>
            );
          })}
        </div>

        <div className="LocationArea TabTarget" id="Location">
          <h2>Location</h2>
          <div className="LocationMainBox">
            <div className="LocationTop">
              <p>
                <i className="fa-solid fa-location-dot"></i>
                {packageListDetails?.package_details?.resort_address},{" "}
                {packageListDetails?.package_details?.resort_pincode}
              </p>
              <span>
                <img
                  src={require("../../assets/images/google-map.png")}
                  alt="googe-map"
                />
                Open in Google Map
              </span>
            </div>
            {/* <iframe src={mapUrl} 
                            width="100%" 
                            height="350" 
                            style={{ border: 0 }} 
                            title="map" 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"></iframe> */}
            <div style={{ height: "60vh", width: "100%" }}>
              {defaultProps && (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyClOj3Kjt1emYwbqRMU9JS4Y9bXwdhS8FY",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  {/* <h3 lat={defaultProps.center.lat} lng={defaultProps.center.lng}>Anshu</h3> */}
                  <Marker
                    lat={defaultProps.center.lat}
                    lng={defaultProps.center.lng}
                    text="Anshu"
                    icon={require("../../assets/icons/map-marker-512.webp")}
                  />
                </GoogleMapReact>
              )}
            </div>
          </div>
        </div>

        <div className="MultiFacilities TabTarget" id="Policies">
          <div className="PoliciesBox Common">
            <h5>Policies</h5>
            <div className="d-flex">
              <aside style={{ width: "100%" }}>
                {packageListDetails?.package_property_policy?.map(
                  (data, index) => {
                    return (
                      // <p key={index}>{data?.property_policies}</p>
                      <p key={index}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.property_policies,
                          }}
                        />
                      </p>
                    );
                  }
                )}

                <p className="mt-4">
                  Learn more about our{" "}
                  <Link to="#">
                    Cancellation policies{" "}
                    <span>
                      <img
                        src={require("../../assets/images/carbon_information.png")}
                        alt="carbon-info"
                      />
                    </span>
                  </Link>
                </p>
              </aside>
            </div>
          </div>
          <div className="CheckInCheckOut Common">
            <h5>Check-in/Check-out</h5>
            <ul>
              {packageListDetails?.package_basic_info?.map((val, index) => {
                return (
                  <>
                    <li key={index}>
                      <span>
                        <img
                          alt="check-in"
                          src={require("../../assets/images/check-in.png")}
                        />
                      </span>
                      Check in after:{" "}
                      {moment(val?.check_in_from, "HH:mm:ss").format("hh:mm A")}
                    </li>
                    <li key={index}>
                      <span>
                        <img
                          alt="check-out"
                          src={require("../../assets/images/check-out.png")}
                        />
                      </span>
                      Check out before:{" "}
                      {moment(val?.check_out_from, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <div className="DamageIncidentals Common">
            <h5>Damage and Incidentals</h5>
            <ul>
              <li>
                You will be responsible for any damage to the rental property
                caused by you or your party during your stay.
              </li>
            </ul>
          </div>
          <div className="HouseRules Common">
            <h5>House Rules</h5>
            <ul>
              <li>Children allowed No Pets.</li>
              <li>No Events</li>
              <li>Max Guess:12</li>
              <li>No Pets</li>
              <li>No Smoking</li>
              <li>Minimum Age of Primary Renter: 25</li>
            </ul>
          </div>
          <div className="GettingAround Common">
            <aside>
              <h5>Getting around</h5>
              <ul>
                <li>Airport transfer fee: 1100 USD</li>
                <li>Travel time to airport (minutes): 10</li>
              </ul>
            </aside>
            <aside>
              <h5>Extra</h5>
              <ul>
                <li>
                  Breakfast charge (unless included in room price): 80 USD
                </li>
                <li>Daily Internet/Wi-Fi fee: 0 USD</li>
              </ul>
            </aside>
          </div>
          <div className="DamageIncidentals Common">
            <h5>Others</h5>
            <ul>
              <li>
                Additional child rates are applicable to children 7-14 years
                old.
              </li>
              <li>
                Children 15 years and over are subject to additional adult
                rates.
              </li>
              <li>
                The additional charges are applicable to all services including
                meals and air transfer fees.
              </li>
              <li>
                When booking more than 5 rooms, different policies and
                additional supplements may apply.
              </li>
            </ul>
          </div>
          <div className="DamageIncidentals Common">
            <h5>The Property</h5>
            <ul>
              <li>Number of bars/lounges: 2</li>
              <li>Number of restaurants: 3</li>
              <li>Number of rooms : 54</li>
              <li>Room voltage: 220</li>
              <li>Year property opened: 2016</li>
            </ul>
          </div>
        </div>

        <div className="RatingReviewsArea TabTarget" id="Review&Rating">
          <div className="RatingReviewBox">
            <aside>
              <h5>Rating and Reviews</h5>
              <div className="RatingsTop">
                <h1>4.7</h1>
                <div className="RatingStar">
                  <i className="fa-solid fa-star checked"></i>
                  <i className="fa-solid fa-star checked"></i>
                  <i className="fa-solid fa-star checked"></i>
                  <i className="fa-solid fa-star checked"></i>
                  <i className="fa-solid fa-star"></i>
                </div>
                <p>(20 Ratings)</p>
              </div>
            </aside>
            <aside>
              <div className="ProgressBarArea">
                <ul>
                  <li>
                    <label>5</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "90%" }}
                      ></span>
                    </div>
                    <strong>250</strong>
                  </li>
                  <li>
                    <label>4</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "60%" }}
                      ></span>
                    </div>
                    <strong>120</strong>
                  </li>
                  <li>
                    <label>3</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "40%" }}
                      ></span>
                    </div>
                    <strong>20</strong>
                  </li>
                  <li>
                    <label>2</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "30%" }}
                      ></span>
                    </div>
                    <strong>10</strong>
                  </li>
                  <li>
                    <label>1</label>
                    <div className="ProgressBar">
                      <span className="Progress" style={{ width: "0%" }}></span>
                    </div>
                    <strong>0</strong>
                  </li>
                </ul>
              </div>
            </aside>
          </div>

          <div className="RatingsBox">
            <aside>
              <div className="UserDetailsBox">
                <h5>David Gedgetin</h5>
                <p>2 days ago</p>
                <span>
                  <img
                    src={require("../../assets/images/profile-1.png")}
                    alt="profile-1"
                  />
                </span>
              </div>
              <div className="RatingStar">
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </aside>
            <aside>
              <p>
                I am very impressed with the services provided by this travel
                agency. They helped me plan my dream trip very well. All the
                details of the trip were perfectly organized and the very
                knowledgeable tour guide made my experience great.
              </p>
            </aside>
          </div>
          <div className="RatingsBox">
            <aside>
              <div className="UserDetailsBox">
                <h5>David Gedgetin</h5>
                <p>2 days ago</p>
                <span>
                  <img
                    src={require("../../assets/images/profile-1.png")}
                    alt="profile-1"
                  />
                </span>
              </div>
              <div className="RatingStar">
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </aside>
            <aside>
              <p>
                I am very impressed with the services provided by this travel
                agency. They helped me plan my dream trip very well. All the
                details of the trip were perfectly organized and the very
                knowledgeable tour guide made my experience great.
              </p>
            </aside>
          </div>
          <div className="RatingsBox">
            <aside>
              <div className="UserDetailsBox">
                <h5>David Gedgetin</h5>
                <p>2 days ago</p>
                <span>
                  <img
                    src={require("../../assets/images/profile-1.png")}
                    alt="profile-1"
                  />
                </span>
              </div>
              <div className="RatingStar">
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </aside>
            <aside>
              <p>
                I am very impressed with the services provided by this travel
                agency. They helped me plan my dream trip very well. All the
                details of the trip were perfectly organized and the very
                knowledgeable tour guide made my experience great.
              </p>
            </aside>
          </div>
          <div className="RatingsBox">
            <aside>
              <div className="UserDetailsBox">
                <h5>David Gedgetin</h5>
                <p>2 days ago</p>
                <span>
                  <img
                    src={require("../../assets/images/profile-1.png")}
                    alt="profile-1"
                  />
                </span>
              </div>
              <div className="RatingStar">
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </aside>
            <aside>
              <p>
                I am very impressed with the services provided by this travel
                agency. They helped me plan my dream trip very well. All the
                details of the trip were perfectly organized and the very
                knowledgeable tour guide made my experience great.
              </p>
            </aside>
          </div>
          <div className="RatingsBox">
            <aside>
              <div className="UserDetailsBox">
                <h5>David Gedgetin</h5>
                <p>2 days ago</p>
                <span>
                  <img
                    src={require("../../assets/images/profile-1.png")}
                    alt="profile-1"
                  />
                </span>
              </div>
              <div className="RatingStar">
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star checked"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </aside>
            <aside>
              <p>
                I am very impressed with the services provided by this travel
                agency. They helped me plan my dream trip very well. All the
                details of the trip were perfectly organized and the very
                knowledgeable tour guide made my experience great.
              </p>
            </aside>
          </div>
          <div className="Paginations">
            <ul>
              <li>
                <Link to="#" className="Grey">
                  <i className="fa-solid fa-chevron-left"></i>
                </Link>
              </li>
              <li>
                <Link to="#">2</Link>
              </li>
              <li>
                <Link to="#">3</Link>
              </li>
              <li>
                <Link to="#">4</Link>
              </li>
              <li>
                <Link to="#">5</Link>
              </li>
              <li>
                <Link to="#" className="Black">
                  <i className="fa-solid fa-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="PinkWarning mt-5">
                    <p><img alt="danger" src={require("../../assets/images/danger.png")} />Check the latest COVID-19 restrictions before you travel. <span>Learn more</span></p>
                </div> */}

        {/* {addMoreList == true && countList >= 1 &&
                    <div className="ReserveBox" style={{display:"block"}}>
                        <a href="booking-details.html">I'll Reserve</Link>
                    </div>
                } */}
      </div>
    </FooterHeaderWrapper>
  );
};

export default PackageDetails;
