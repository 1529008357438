import { Link, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import actions from "../../redux/actions";
import { ToastContainer, toast } from 'react-toastify';
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminHeader = () => {
    const navigate = useNavigate();
    const logOut = (e) => {
        e.preventDefault();
        localStorage.clear();
        // window.location.href = '/';
        // navigate("/");
        window.location.reload();
    }

    const location = useLocation();
    const isHome = location.pathname === '/';

    let user_details = !!localStorage.getItem('user_details') ? JSON.parse(localStorage.getItem('user_details')) : {};

    //resort list by type
    const handleResortList = async (e, data) => {
        let res = await actions.getResortListByTypeAction(data);
        if (res.data.success === true) {
            navigate('/resort-list', { state: { searchData: { holidayType: data, checkInDate: null, checkOutDate: null } } })
            // navigate('/resort-list',{state:{searchData:res.data}})
        } else {
            toast.warning(res.data.message);
        }
    }

    // Header scrolling background
    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('header');
            if (header) {
                header.classList.toggle('header-scrolled', window.scrollY > 50);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

 

    const holidayTypeList = useSelector((state) => state?.resort?.resortHolidayList?.data);
    useEffect(() => {
        actions.getResortHolidayAction();
    }, []);

    // console.log('holiday type list admin header --- ', holidayTypeList);

    return (
        <header className={isHome ? '' : 'Header'}>
            <nav className="navbar navbar-expand-lg">
                <Link to="/" className="navbar-brand">
                    <img src={require("../../assets/images/logo.png")} alt="logo-img" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fa-solid fa-bars"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <Link to="/" className="nav-link">Welcome</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-us" className="nav-link">Our Story</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/packages" className="nav-link">Curated Collections</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                Escapes
                            </Link>
                            <ul className="dropdown-menu">
                                {
                                    holidayTypeList && holidayTypeList?.map((item, index) => (
                                        <li key={index}>
                                            <Link to="#" className="dropdown-item" value={item?.holiday_type} onClick={(e) => handleResortList(e, item?.holiday_type)}>{item?.holiday_type}</Link>
                                        </li>
                                    ))
                                }
                                {/* {
                                    resortList?.resort_type_list && resortList?.resort_type_list?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <Link to="#" className="dropdown-item" value={item?.holiday_type} onClick={(e) => handleResortList(e, item?.holiday_type)}>{item?.holiday_type}</Link>
                                            </li>
                                        )
                                    })
                                } */}
                                {/* <li><Link to='#' onClick={(e) => handleResortList(e, "honeymoon")} className="dropdown-item">Honeymoon</Link></li>
                                <li><Link to='#' onClick={(e) => handleResortList(e, "family")} className="dropdown-item">Family</Link>
                                    <ul className="deep-dropdown-menu">
                                            <li><Link to='#'>Lakeside Motel Warefront</Link></li>
                                            <li><Link to='#'>Lakeside Motel Warefront</Link></li>
                                            <li><Link to='#'>Lakeside Motel Warefront</Link></li>
                                            <li><Link to='#'>Lakeside Motel Warefront</Link></li>
                                            <li><Link to='#'>Lakeside Motel Warefront</Link></li>
                                            <li><Link to='#'>Lakeside Motel Warefront</Link></li>
                                            <li><Link to='#'>Lakeside Motel Warefront</Link></li>
                                        </ul>
                                </li>
                                <li><Link to='#' onClick={(e) => handleResortList(e, "fishing")} className="dropdown-item">Fishing</Link></li>
                                <li><Link to='#' onClick={(e) => handleResortList(e, "Diving")} className="dropdown-item">Diving</Link></li>
                                <li><Link to='#' onClick={(e) => handleResortList(e, "surfing")} className="dropdown-item">Surfing</Link></li>
                                <li><Link to='#' onClick={(e) => handleResortList(e, "scating")} className="dropdown-item">Scating</Link></li> */}
                            </ul>
                        </li>
                    </ul>
                </div>


                <div className="UserLoginDetails">

                    <div className="dropdown">
                        <Link to="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i className="fa-solid fa-bars-staggered"></i>
                            <span className="Logo">
                                <img alt="user-details" src={user_details?.image ? user_details?.image : require("../../assets/images/avatar.png")} />
                            </span>
                        </Link>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <div className="UserProfile">
                                <span><img alt="user-profile" src={user_details?.image ? user_details?.image : require("../../assets/images/avatar.png")} /></span>
                                <h6>{user_details?.first_name} {user_details?.last_name}</h6>
                            </div>
                            <ul>
                                <li><Link to="/booking-list"><span><img alt="home" src={require("../../assets/images/home-icon.png")} /></span>My Bookings</Link></li>
                            </ul>
                            <div className="Button">
                                <Link to="/profile-details" className="AccountBtn">Account</Link>
                                <Link to="#" onClick={logOut} className="LogoutBtn" >Log Out</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <ToastContainer />
        </header>
    )
}

export default AdminHeader;