const prod = {
    API_BASE_URL: "https://shadescapesmv.com/cms",
    IMAGES_BASE_URL: "https://shadescapesmv.com/cms/public/",
    PAYMENT_URL: "https://api.uat.merchants.bankofmaldives.com.mv",
}

const dev = {
    API_BASE_URL: "http://localhost:8008/proxy",
    IMAGES_BASE_URL: "http://127.0.0.1:8000/public/",
    PAYMENT_URL: "http://localhost:8080/proxy",


    // API_BASE_URL:"https://shadescapesmv.com/cms",
    // IMAGES_BASE_URL: "https://shadescapesmv.com/cms/public/"

    // API_BASE_URL:"http://54.204.28.114",
    // IMAGES_BASE_URL: "http://54.204.28.114/public/"
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;  