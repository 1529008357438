import { useLocation, useParams } from "react-router-dom";
import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ZohoChatSupport from "../../components/chat";
import GoogleMapReact from "google-map-react";
import DatePicker from "react-datepicker";
import Carousel from "react-bootstrap/Carousel";
import "react-datepicker/dist/react-datepicker.css";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";

const ProductDetails = () => {
  let user_details = JSON.parse(localStorage.getItem("user_details"));

  let { resort_id } = useParams();
  const location = useLocation();
  const [img_index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  
  const options = {
    items: 1,
    nav: true,
    navText: ["<i class='fa-solid fa-angle-left'></i>", "<i class='fa-solid fa-angle-right'></i>"],
    // navText: ["<img src='https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/news/Arrow.png'>","<img src='https://mobulous.s3.ap-south-1.amazonaws.com/Web/images/news/Arrow.png'>"],
    rewind: true,
    autoplay: true,
    loop: true,
  };

  const CloneformData = location?.state?.formData;
  const filterData = location?.state?.filterData;
  console.log("state", location?.state);

  // console.log('product details resort_id  ---- ',resort_id);
  // const uniqueAmenities = new Set();
  const Navigate = useNavigate();

  //const [addMoreList, setAddMoreList] = useState(false);
  const [countList, setCountList] = useState([]);
  const [activeTab, setActiveTab] = useState("Overview");

  let resortListDetails = useSelector(
    (state) => state.resort.resortListDetails
  );
  useEffect(() => {
    actions.getResortListDetailsAction(resort_id);
  }, [resort_id]);
  console.log("asdf", resortListDetails);

  const getBestPrice = () => {
    toast.warning("Login to get best price");
  };

  const handleCountData = (e, id) => {
    setCountList([...countList, { count: 1, id }]);
  };

  const handleCountPlus = (id) => {
    setCountList(
      countList
        .map((data) => {
          if (data.id === id) {
            const newCount = (data.count || 0) + 1;
            if (newCount <= 0) {
              return null;
            } else {
              return { ...data, count: newCount };
            }
          }
          return data;
        })
        .filter(Boolean)
    );
  };

  const handleCountMinus = (id) => {
    setCountList(
      countList
        .map((data) => {
          if (data.id === id) {
            const newCount = (data.count || 0) - 1;
            if (newCount <= 0) {
              return null;
            } else {
              return { ...data, count: newCount };
            }
          }
          return data;
        })
        .filter(Boolean)
    );
  };
  console.log("count lit --- ", countList);

  const scrollToTab = (id) => {
    setActiveTab(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  //calculate amount
  const [totalAmount, setTotalAmount] = useState({
    totalResult: "",
    totalDiscount: "",
  });

  useEffect(() => {
    let totalResult = [];
    let totalDiscount = [];
    resortListDetails?.resort_pricing?.map((val) =>
      val?.resort_pricing_date_range?.map((value) =>
        totalResult.push(parseFloat(value?.base_price_per_night))
      )
    );
    resortListDetails?.resort_pricing?.map((val) =>
      val?.resort_pricing_date_range?.map((value) =>
        totalDiscount.push(
          parseFloat(value?.base_price_per_night) -
            parseFloat(value?.base_discounted_price)
        )
      )
    );

    setTotalAmount({ ...totalAmount, totalResult, totalDiscount });
  }, [resortListDetails]);

  //handle booking
  const handleReserve = () => {
    Navigate(`/resort-booking-details`, {
      state: {
        reserveData: countList,
        calculatedAmount: totalAmount,
        resortId: resort_id,
        searchData: formData,
      },
    });
  };

  //room level amenities
  const roomLevelUniqueAmenities = new Set();

  resortListDetails?.resort_amenities?.forEach((data) => {
    data?.room_level_amenities?.forEach((val) => {
      roomLevelUniqueAmenities.add(val?.room_level_amenities);
    });
  });
  const roomLevelUniqueAmenitiesArray = Array.from(roomLevelUniqueAmenities);

  // map
  const [defaultProps, setDefaultProps] = useState(null);

  const lat = resortListDetails?.resort_details?.latitude;
  const lng = resortListDetails?.resort_details?.longitude;

  useEffect(() => {
    if (lat && lng) {
      setDefaultProps({
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        zoom: 11,
      });
    }
  }, [lat, lng]);

  const Marker = ({ text, icon }) => (
    <div
      style={{
        position: "relative",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img src={icon} alt={text} style={{ width: "50px", height: "50px" }} />
      <div
        style={{
          position: "absolute",
          bottom: "-25px",
          left: "50%",
          transform: "translateX(-50%)",
          color: "black",
          background: "white",
          padding: "2px 5px",
          borderRadius: "3px",
          fontSize: "12px",
        }}
      >
        {text}
      </div>
    </div>
  );

  //calander on hover
  const today = new Date();
  const currentDate = moment(today).format("YYYY-MM-DD");
  const nextDate = moment(currentDate, "YYYY-MM-DD")
    .add(2, "days")
    .format("YYYY-MM-DD");

  const [formData, setFormData] = useState({
    checkInDate: currentDate,
    checkOutDate: nextDate,
    rooms: 1,
    adults: 2,
    childrens: 0,
    infant: 0,
  });

  console.log("date format formdata ---- ", formData);

  const [showCalendar, setShowCalendar] = useState(false);
  const [showCheckOutCalander, setShowCheckOutCalander] = useState(false);

  const handleMouseEnter = () => {
    setShowCalendar(true);
  };
  const handleMouseLeave = () => {
    setShowCalendar(false);
  };

  const handleMouseEnterCheckOut = () => {
    setShowCheckOutCalander(true);
  };
  const handleMouseLeaveCheckOut = () => {
    setShowCheckOutCalander(false);
  };

  const handleIncrement = () => {
    setFormData((prevState) => ({ ...prevState, rooms: prevState?.rooms + 1 }));
  };
  const handleDecrement = () => {
    if (formData?.rooms > 0) {
      setFormData((prevState) => ({
        ...prevState,
        rooms: prevState?.rooms - 1,
      }));
    }
  };

  const handleAdultIncrement = () => {
    setFormData((prevState) => ({
      ...prevState,
      adults: prevState?.adults + 1,
    }));
  };
  const handleAdultDecrement = () => {
    if (formData?.adults > 0) {
      setFormData((prevState) => ({
        ...prevState,
        adults: prevState?.adults - 1,
      }));
    }
  };

  const handleChildrenIncrement = () => {
    setFormData((prevState) => ({
      ...prevState,
      childrens: prevState?.childrens + 1,
    }));
  };
  const handleChildrenDecrement = () => {
    if (formData?.childrens > 0) {
      setFormData((prevState) => ({
        ...prevState,
        childrens: prevState?.childrens - 1,
      }));
    }
  };

  const handleInfantIncrement = () => {
    setFormData((prevState) => ({
      ...prevState,
      infant: prevState?.infant + 1,
    }));
  };
  const handleInfantDecrement = () => {
    if (formData?.infant > 0) {
      setFormData((prevState) => ({
        ...prevState,
        infant: prevState?.infant - 1,
      }));
    }
  };

  useEffect(() => {
    if (location?.state) {
      setFormData({
        ...formData,
        checkInDate: filterData?.checkInDate,
        checkOutDate: filterData?.checkOutDate,
        rooms: CloneformData?.rooms,
        adults: CloneformData?.adults,
        childrens: CloneformData?.childrens,
        infant: CloneformData?.infant,
      });
    }
  }, [location?.state]);

  //rating
  const rating_grades = ["Poor", "Fair", "Good", "Very good", "Excellent"];
  let current_date = moment(new Date()).format("YYYY-MM-DD");

  console.log(
    "resortListDetails",
    resortListDetails?.resort_details?.resort_rating_data?.resort_rating?.find(
      (item) => item?.star_rating === 5
    )
  );

  return (
    <FooterHeaderWrapper>
      <div className="HotelBreadcumb Position">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/resort-list">Resorts</Link>
          </li>
          <li className="active">Resort Details</li>
        </ul>
      </div>
      <div className="IndividualHotelDetail">
        <div>
          {/* {resortListDetails?.resort_basic_info?.map((data, index) => (
                        <h1 key={index}>{data?.resort_name}</h1>
                    ))} */}

          <h1>{resortListDetails?.resort_details?.resort_name}</h1>

          {resortListDetails?.resort_details?.resort_rating_data
            ?.resort_rating_count !== 0 ? (
            <h6>
              <span className="Green">
                {resortListDetails?.resort_details?.resort_rating_data?.average_rating?.toFixed(
                  1
                )}
              </span>
              Excellent
              <p>
                (
                {
                  resortListDetails?.resort_details?.resort_rating_data
                    ?.resort_rating_count
                }{" "}
                Reviews)
              </p>
            </h6>
          ) : (
            <h6>
              <span className="Yellow"> No ratings yet </span>
            </h6>
          )}

          <p>
            <i className="fa-solid fa-location-dot"></i>
            {resortListDetails?.resort_details?.resort_address} ,{" "}
            {resortListDetails?.resort_details?.resort_pincode}
          </p>
        </div>
        <div>
          {/* <span className="Maps" id="chatSupport"><img src={require("../../assets/images/chat-support.png")} alt="chat" />Chat Support</span> */}
          <ZohoChatSupport />
        </div>
      </div>
      <div className="IndividualHotelImages">
        <div className="row">
          <div className="col-md-6">
            <figure>
              <img
                src={
                  resortListDetails?.resort_details?.images
                    ? resortListDetails?.resort_details?.images
                    : require("../../assets/images/select-1.png")
                }
                alt="package-details"
                width="100%"
              />
            </figure>
          </div>
          <div className=" col-md-6">
            <div className="row">
              {resortListDetails?.resort_basic_info?.map((val) => {
                return val?.images?.map((imageUrl, index) => (
                  <>
                    <div className=" col-md-6" key={index}>
                      <figure>
                        <img
                          src={
                            imageUrl
                              ? imageUrl
                              : require("../../assets/images/select-2.png")
                          }
                          alt="package-details-1"
                          width="100%"
                          height="100%"
                        />
                      </figure>
                    </div>
                  </>
                ));
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="FacilitiesTabs">
        <ul>
          <li>
            <a
              href="#Overview"
              className={activeTab === "Overview" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Overview");
              }}
            >
              Overview
            </a>
          </li>
          <li>
            <a
              href="#Highlights"
              className={activeTab === "Highlights" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Highlights");
              }}
            >
              Highlights
            </a>
          </li>
          <li>
            <a
              href="#Facilities"
              className={activeTab === "Facilities" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Facilities");
              }}
            >
              Facilities
            </a>
          </li>
          <li>
            <a
              href="#Rooms"
              className={activeTab === "Rooms" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Rooms");
              }}
            >
              Rooms
            </a>
          </li>
          <li>
            <a
              href="#Location"
              className={activeTab === "Location" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Location");
              }}
            >
              Location
            </a>
          </li>
          <li>
            <a
              href="#Policies"
              className={activeTab === "Policies" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Policies");
              }}
            >
              Policies
            </a>
          </li>
          <li>
            <a
              href="#Review&Rating"
              className={activeTab === "Review&Rating" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                scrollToTab("Review&Rating");
              }}
            >
              Review & Rating
            </a>
          </li>
        </ul>
      </div>

      <div className="DetailsGrey">
        <div className="OverViewBox TabTarget" id="Overview">
          <h4>Overview</h4>
          <p>
            {resortListDetails?.resort_basic_info?.map((val, index) => {
              return (
                <>
                  <p key={index}>
                    <div
                      dangerouslySetInnerHTML={{ __html: val?.about_resort }}
                    />
                  </p>
                </>
              );
            })}
          </p>
        </div>

        <div className="HighlightsBox TabTarget" id="Highlights">
          <h4>Highlights</h4>
          <ul>
            <li>
              <span>
                <img
                  alt="wifi"
                  src={require("../../assets/images/home-wifi.png")}
                />
              </span>
              Free wifi
            </li>
            <li>
              <span>
                <img
                  alt="business-service"
                  src={require("../../assets/images/business-service.png")}
                />
              </span>
              Business Services
            </li>
            <li>
              <span>
                <img
                  alt="air-condition"
                  src={require("../../assets/images/air-conditioning.png")}
                />
              </span>
              Air Conditioning
            </li>
            <li>
              <span>
                <img
                  alt="swimming"
                  src={require("../../assets/images/swimming.png")}
                />
              </span>
              Swimming Pool
            </li>
            <li>
              <span>
                <img
                  alt="airport"
                  src={require("../../assets/images/airport.png")}
                />
              </span>
              Airport Transfer
            </li>
            <li>
              <span>
                <img
                  alt="top-rated"
                  src={require("../../assets/images/top-rated.png")}
                />
              </span>
              Top rated in area
            </li>
            <li>
              <span>
                <img
                  alt="top-rated"
                  src={require("../../assets/images/top-rated.png")}
                />
              </span>
              Top rated in area
            </li>
            <li>
              <span>
                <img
                  alt="top-rated"
                  src={require("../../assets/images/top-rated.png")}
                />
              </span>
              Top rated in area
            </li>
          </ul>
        </div>

        <div className="FacilitiesBox TabTarget" id="Facilities">
          <h4>Facilities</h4>
          <ul>
            {/* {
                            resortListDetails?.resort_amenities?.forEach((val) => {

                                val?.amenities?.split(', ')?.forEach((item) => {
                                    const trimmedItem = item?.trim();

                                    uniqueAmenities.add(trimmedItem);

                                });

                            })
                        }
                        {
                            [...uniqueAmenities]?.map((uniqueItem, index) => (
                                <li key={index}>{uniqueItem}</li>
                            ))
                        } */}
            {resortListDetails?.unique_amenities?.map((val, index) => (
              <li key={index}>{val}</li>
            ))}
          </ul>
        </div>

        <div className="AvailableRoomsArea TabTarget" id="Rooms">
          <h2>Available Rooms</h2>

          <div className="CheckAvailability">
            <div className="form-group NewGreyBox HeroSmallBox HeroSmallBoxDate">
              <div className="Overlay">
                <span>
                  <img
                    alt="date"
                    src={require("../../assets/images/date-icon.png")}
                  />
                </span>
              </div>
              {/* <input type="text"  /> */}
              <div
                className="hover-datepicker"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <DatePicker
                  width="100%"
                  // minDate={today}
                  selected={formData?.checkInDate}
                  onChange={(date) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      checkInDate: date,
                    }))
                  }
                  open={showCalendar}
                  onFocus={() => setShowCalendar(true)}
                  onBlur={() => setShowCalendar(false)}
                  dateFormat="MMMM dd, yyyy"
                  placeholderText="Month DD, YYYY"
                  name="checkInDate"
                  monthsShown={2}
                  selectsStart
                  // selectsEnd
                  // selectsRange={true}
                  // onChange={(update) => {
                  //     setFormData((prevState) => ({ ...prevState, checkInDate: update }))
                  //   }}
                  startDate={formData?.checkInDate}
                  endDate={formData?.checkOutDate}
                />
              </div>
            </div>
            <div className="form-group NewGreyBox HeroSmallBox HeroSmallBoxDate">
              <div className="Overlay">
                <span>
                  <img
                    alt="date"
                    src={require("../../assets/images/date-icon.png")}
                  />
                </span>
              </div>

              <div
                className="hover-datepicker"
                onMouseEnter={handleMouseEnterCheckOut}
                onMouseLeave={handleMouseLeaveCheckOut}
              >
                <DatePicker
                  width="100%"
                  open={showCheckOutCalander}
                  onFocus={() => setShowCheckOutCalander(true)}
                  onBlur={() => setShowCheckOutCalander(false)}
                  dateFormat="MMMM dd, yyyy"
                  placeholderText="Month DD, YYYY"
                  className="form-control"
                  name="checkOutDate"
                  selected={formData?.checkOutDate}
                  onChange={(date) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      checkOutDate: date,
                    }))
                  }
                  minDate={formData?.checkInDate}
                  selectsEnd
                  startDate={formData?.checkInDate}
                  endDate={formData?.checkOutDate}
                  monthsShown={2}
                />
              </div>
            </div>
            <div className="form-group Custom RoomsTypeHover">
              <p>Villas ({formData?.rooms})</p>
              <div className="Overlay">
                <span>
                  <img
                    alt="overlay"
                    src={require("../../assets/images/icon-2.png")}
                  />
                </span>
              </div>
              <div className="RoomsTypesOuter First CheckAvailabilityShow">
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Villas</h6>
                    <p>Number of Villas</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>{formData?.rooms}</h6>
                    <input type="hidden" name="rooms" value={formData?.rooms} />
                    <span onClick={handleIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group Custom TravelersHover">
              <p>
                Guest Details ({formData?.adults} + {formData?.childrens} +{" "}
                {formData?.infant})
              </p>
              <div className="Overlay">
                <span>
                  <img
                    alt="icon-3"
                    src={require("../../assets/images/icon-3.png")}
                  />
                </span>
              </div>
              <div className="RoomsTypesOuter Second CheckAvailabilityShow">
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Adults</h6>
                    <p>Ages 13 or above</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleAdultDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>
                      {formData?.adults}{" "}
                      <input
                        type="hidden"
                        name="adults"
                        value={formData?.adults}
                      />
                    </h6>
                    <span onClick={handleAdultIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Childred</h6>
                    <p>Ages 2 - 12</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleChildrenDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>
                      {formData?.childrens}{" "}
                      <input
                        type="hidden"
                        name="childrens"
                        value={formData?.childrens}
                      />
                    </h6>
                    <span onClick={handleChildrenIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
                <div className="RoomsTypesList">
                  <div className="RoomsTypeLeft">
                    <h6>Infant</h6>
                    <p>Ages 0 - 2</p>
                  </div>
                  <div className="RoomsTypeRight">
                    <span onClick={handleInfantDecrement}>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                    <h6>
                      {formData?.infant}{" "}
                      <input
                        type="hidden"
                        name="infant"
                        value={formData?.infant}
                      />
                    </h6>
                    <span onClick={handleInfantIncrement}>
                      <i className="fa-solid fa-plus"></i>
                    </span>
                  </div>
                </div>
                {/* <div className="RoomsTypesList">
                                    <div className="RoomsTypeLeft">
                                        <h6>Adults</h6>
                                        <p>Under 2</p>
                                    </div>
                                    <div className="RoomsTypeRight">
                                        <span><i className="fa-solid fa-minus"></i></span>
                                        <h6>0</h6>
                                        <span><i className="fa-solid fa-plus"></i></span>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="form-group">
              <Link to="#" className="CheckAvailabilityBtn">
                Check Availability
              </Link>
            </div>
          </div>

          {resortListDetails?.resort_room_types?.map((val, index) => {
            // let new_images = val?.images?.split(",");
            // console.log("new images---------------------- ", new_images);
            return (
              <>
                <div className="CheckOutRoom" key={index}>
                  <div className="CheckOutRoomSlider">
                    <OwlCarousel options={options}>
                      {val?.images?.length > 1
                        ? val?.images?.map((img, i) => {
                            return (
                              <div key={i}>
                                <figure>
                                  <img src={img} />
                                </figure>
                              </div>
                            );
                          })
                        : ""}
                    </OwlCarousel>
                  </div>

                  <div className="CheckOutContentMain">
                    <div className="CheckOutContent">
                      <div className="CheckOutLeft">
                        <h5>{val?.room_title}</h5>
                        <ul className="First">
                          <li>
                            <span>
                              <img
                                alt="sq-ft"
                                src={require("../../assets/images/sq-ft.png")}
                              />
                            </span>
                            {val?.room_size_value ? val?.room_size_value : "30"}{" "}
                            {val?.room_size_unit === "sq_feet"
                              ? "sq ft"
                              : "sq mtr"}
                          </li>
                          <li>
                            <span>
                              <img
                                alt="sq-ft"
                                src={require("../../assets/images/sq-ft.png")}
                              />
                            </span>
                            {val?.bed_size}
                          </li>
                          <li>
                            <span>
                              <img
                                alt="sq-ft"
                                src={require("../../assets/images/sq-ft.png")}
                              />
                            </span>
                            City View
                          </li>
                        </ul>
                        <ul className="Second">
                          {resortListDetails?.resort_meal_supplement?.map(
                            (data, index) => (
                              <li key={index}>
                                <i className="fa-solid fa-check"></i>
                                {data?.meal_name}
                              </li>
                            )
                          )}
                          {roomLevelUniqueAmenitiesArray.map((amenity) =>
                            amenity?.split(",")?.map((val, index) => (
                              <li key={index}>
                                <i className="fa-solid fa-check"></i>
                                {val}
                              </li>
                            ))
                          )}
                        </ul>
                        <Link to="#">
                          <span>
                            <img
                              alt="carbon-information"
                              src={require("../../assets/images/carbon_information.png")}
                            />
                          </span>
                          Cancellation Policy
                        </Link>
                      </div>
                      <div className="CheckOutRight">
                        <div className="CheckOutRightTop">
                          {/* {
                                                            val?.room_pricing?.map((room_price, roomIndex) => (
                                                                user_details ?
                                                                    <h4 key={roomIndex}><span>${room_price?.base_price_per_night}</span>${room_price?.base_price_per_night - room_price?.base_discounted_price}</h4>
                                                                    :
                                                                    <h4 key={roomIndex}>${room_price?.base_price_per_night}</h4>
                                                            ))
                                                        }

                                                        {
                                                            val?.room_pricing?.map((room_price, roomIndex) => (
                                                                <p key={roomIndex}>{room_price?.taxes == 'include_taxes' ? 'Includes taxes and fees' : 'Taxes and fees are not included'}</p>
                                                            ))
                                                        } */}

                          {user_details ? (
                            <h4>
                              <span>
                                ${val?.room_pricing[0]?.base_price_per_night}
                              </span>
                              $
                              {val?.room_pricing[0]?.base_price_per_night -
                                val?.room_pricing[0]?.base_discounted_price}
                            </h4>
                          ) : (
                            <h4>
                              ${val?.room_pricing[0]?.base_price_per_night}
                            </h4>
                          )}

                          <p>
                            {val?.room_pricing[0]?.taxes == "include_taxes"
                              ? "Includes taxes and fees"
                              : "Taxes and fees are not included"}
                          </p>
                        </div>
                        <div className="CheckOutRightBottom">
                          {/* <Link to={`/booking-details/${val?.id}`} className="BookBtn SelectRoomBtn">Select Room</Link> */}
                          {/* <Link to="#" onClick={(e) => handleCountData(e, val?.id)} className="BookBtn SelectRoomBtn">Select Room</Link> */}

                          <Link
                            to="#"
                            onClick={(e) => handleCountData(e, val?.id)}
                            className="BookBtn SelectRoomBtn"
                          >
                            Select Room
                          </Link>

                          {user_details ? (
                            ""
                          ) : (
                            <Link
                              to="#"
                              onClick={getBestPrice}
                              className="LoginLink"
                            >
                              Login to unlock the best price
                            </Link>
                          )}

                          {user_details
                            ? countList.some((item) => item.id === val.id) && (
                                <div
                                  className="PlusMinusBoxMain"
                                  style={{ display: "block" }}
                                  key={index}
                                >
                                  <div className="PlusMinusBox">
                                    <span
                                      onClick={() => handleCountMinus(val.id)}
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </span>
                                    <h6>
                                      {countList.find(
                                        (item) => item.id === val.id
                                      )?.count || 0}{" "}
                                      Room
                                    </h6>
                                    <span
                                      onClick={() => handleCountPlus(val.id)}
                                    >
                                      {" "}
                                      <i className="fa-solid fa-plus"></i>
                                    </span>
                                  </div>
                                </div>
                              )
                            : countList.some((item) => item.id === val.id) && (
                                <div
                                  className="PlusMinusBoxMain"
                                  style={{ display: "block" }}
                                  key={index}
                                >
                                  <div className="PlusMinusBox">
                                    <span
                                      onClick={() => handleCountMinus(val.id)}
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </span>
                                    <h6>
                                      {countList.find(
                                        (item) => item.id === val.id
                                      )?.count || 0}{" "}
                                      Room
                                    </h6>
                                    <span
                                      onClick={() => handleCountPlus(val.id)}
                                    >
                                      {" "}
                                      <i className="fa-solid fa-plus"></i>
                                    </span>
                                  </div>
                                </div>
                              )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="CheckOutContent" style={{ border: 'none' }}>
                                                <div className="CheckOutLeft">
                                                    <ul className="Second">
                                                        {
                                                            resortListDetails?.resort_meal_supplement?.map((data, index) => (
                                                                <li key={index}><i className="fa-solid fa-check"></i>{data?.meal_name}</li>
                                                            ))
                                                        }
                                                        {roomLevelUniqueAmenitiesArray.map((amenity, index) => (
                                                            <li key={index}><i className="fa-solid fa-check"></i>{amenity}</li>
                                                        ))}
                                                    </ul>
                                                    <Link to="#"><span><img alt="carbon" src={require("../../assets/images/carbon_information.png")} /></span>Cancellation Policy</Link>
                                                    <div className="CheckOutBottom">
                                                        <p><span><img alt="icon-3" src={require("../../assets/images/icon-3.png")} /></span>{val?.adults} adult, {val?.children} children (Below 12 years)</p>
                                                    </div>
                                                </div>
                                                <div className="CheckOutRight">
                                                    <div className="CheckOutRightTop">
                                                        {
                                                            (() => {
                                                                let totalResult = [];
                                                                let totalDiscount = [];
                                                                resortListDetails?.resort_pricing?.map((val, index) => (
                                                                    val?.resort_pricing_date_range?.map((value, index) => (
                                                                        totalResult.push(parseFloat(value?.base_price_per_night))
                                                                    ))

                                                                ));
                                                                resortListDetails?.resort_pricing?.map((val, index) => (
                                                                    val?.resort_pricing_date_range?.map((value, index) => (
                                                                        totalDiscount.push(parseFloat(value?.base_price_per_night) - parseFloat(value?.base_discounted_price))
                                                                    ))

                                                                ));
                                                                return (
                                                                    <h4><span>${totalResult[index]}</span>${totalDiscount[index]}</h4>
                                                                );
                                                            })()
                                                        }
                                                        <p>Includes taxes and fees</p>
                                                    </div>
                                                    <div className="CheckOutRightBottom">
                                                        
                                                        {
                                                            user_details ?
                                                                <Link to="#" onClick={(e) => handleCountData(e, val?.id)} className="BookBtn SelectRoomBtn">Select Room</Link>
                                                                :
                                                                <Link to="#" className="BookBtn SelectRoomBtn">Select Room</Link>
                                                        }

                                                        {user_details ? countList.some(item => item.id === val.id) && (
                                                            <div className="PlusMinusBoxMain" style={{ display: "block" }} key={index}>
                                                                <div className="PlusMinusBox">
                                                                    <span onClick={() => handleCountMinus(val.id)}><i className="fa-solid fa-minus"></i></span>
                                                                    <h6>{countList.find(item => item.id === val.id)?.count || 0} Room</h6>
                                                                    <span onClick={() => handleCountPlus(val.id)}> <i className="fa-solid fa-plus"></i></span>
                                                                </div>
                                                            </div>
                                                        ) : ''}
                                                        <div className="PlusMinusBoxMain">
                                                            <div className="PlusMinusBox">
                                                                <span><i className="fa-solid fa-minus"></i></span>
                                                                <h6>1 Room</h6>
                                                                <span><i className="fa-solid fa-plus"></i></span>
                                                            </div>
                                                        </div>
                                                        {
                                                            user_details ?
                                                                ""
                                                                :
                                                                <Link to="#" onClick={getBestPrice} className="LoginLink">Login to unlock the best price</Link>

                                                        }
                                                    </div>
                                                </div>
                                            </div> */}
                  </div>
                </div>
                {countList?.length > 0 && (
                  <div
                    onClick={handleReserve}
                    className="ReserveBox"
                    style={{
                      display: "block",
                      padding: "10px",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    {/* <a href="booking-details.html">I'll Reserve</Link> */}
                    <span>I'll Reserve</span>
                  </div>
                )}
              </>
            );
          })}
        </div>

        <div className="LocationArea TabTarget" id="Location">
          <h2>Location</h2>
          <div className="LocationMainBox">
            <div className="LocationTop">
              <p>
                <i className="fa-solid fa-location-dot"></i>
                {resortListDetails?.resort_details?.resort_address} ,{" "}
                {resortListDetails?.resort_details?.resort_pincode}
              </p>
              <span>
                <img
                  src={require("../../assets/images/google-map.png")}
                  alt="googe-map"
                />
                Open in Google Map
              </span>
            </div>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52938394.04350317!2d-161.92225315781042!3d35.91997508297217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1708605706990!5m2!1sen!2sin" width="100%" height="350" style={{ border: 0 }} title="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}

            <div style={{ height: "60vh", width: "100%" }}>
              {defaultProps && (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyClOj3Kjt1emYwbqRMU9JS4Y9bXwdhS8FY",
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  {/* <h3 lat={defaultProps.center.lat} lng={defaultProps.center.lng}>Anshu</h3> */}
                  <Marker
                    lat={defaultProps.center.lat}
                    lng={defaultProps.center.lng}
                    text="Anshu"
                    icon={require("../../assets/icons/map-marker-512.webp")}
                  />
                </GoogleMapReact>
              )}
            </div>
          </div>
        </div>

        <div className="MultiFacilities TabTarget" id="Policies">
          <div className="PoliciesBox Common">
            <h5>Policies</h5>
            <div className="d-flex">
              <aside>
                <p>Bookings at this property are non-refundable.</p>

                <p className="mt-4">
                  Learn more about our{" "}
                  <Link to="#">
                    Cancellation policies{" "}
                    <span>
                      <img
                        src={require("../../assets/images/carbon_information.png")}
                        alt="carbon-info"
                      />
                    </span>
                  </Link>
                </p>
              </aside>
              <aside>
                <p>
                  If you have upcoming trips, you can manage or <br /> cancel
                  your booking in your traveler account.
                </p>
              </aside>
            </div>
          </div>
          <div className="CheckInCheckOut Common">
            <h5>Check-in/Check-out</h5>
            <ul>
              {resortListDetails?.resort_basic_info?.map((val, index) => {
                return (
                  <>
                    <li key={index}>
                      <span>
                        <img
                          alt="check-in"
                          src={require("../../assets/images/check-in.png")}
                        />
                      </span>
                      Check in after:{" "}
                      {moment(val?.check_in_from, "HH:mm:ss").format("hh:mm A")}
                    </li>
                    <li key={index}>
                      <span>
                        <img
                          alt="check-out"
                          src={require("../../assets/images/check-out.png")}
                        />
                      </span>
                      Check out before:{" "}
                      {moment(val?.check_out_from, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <div className="DamageIncidentals Common">
            <h5>Damage and Incidentals</h5>
            <ul>
              <li>
                You will be responsible for any damage to the rental property
                caused by you or your party during your stay.
              </li>
            </ul>
          </div>
          <div className="HouseRules Common">
            <h5>House Rules</h5>
            <ul>
              <li>Children allowed No Pets.</li>
              <li>No Events</li>
              <li>Max Guess:12</li>
              <li>No Pets</li>
              <li>No Smoking</li>
              <li>Minimum Age of Primary Renter: 25</li>
            </ul>
          </div>
          <div className="GettingAround Common">
            <aside>
              <h5>Getting around</h5>
              <ul>
                <li>Airport transfer fee: 1100 USD</li>
                <li>Travel time to airport (minutes): 10</li>
              </ul>
            </aside>
            <aside>
              <h5>Extra</h5>
              <ul>
                <li>
                  Breakfast charge (unless included in room price): 80 USD
                </li>
                <li>Daily Internet/Wi-Fi fee: 0 USD</li>
              </ul>
            </aside>
          </div>
          <div className="DamageIncidentals Common">
            <h5>Others</h5>
            <ul>
              <li>
                Additional child rates are applicable to children 7-14 years
                old.
              </li>
              <li>
                Children 15 years and over are subject to additional adult
                rates.
              </li>
              <li>
                The additional charges are applicable to all services including
                meals and air transfer fees.
              </li>
              <li>
                When booking more than 5 rooms, different policies and
                additional supplements may apply.
              </li>
            </ul>
          </div>
          <div className="DamageIncidentals Common">
            <h5>The Property</h5>
            <ul>
              <li>Number of bars/lounges: 2</li>
              <li>Number of restaurants: 3</li>
              <li>Number of rooms : 54</li>
              <li>Room voltage: 220</li>
              <li>Year property opened: 2016</li>
            </ul>
          </div>
        </div>

        <div className="RatingReviewsArea TabTarget" id="Review&Rating">
          <div className="d-flex">
            <aside style={{ width: "30%" }}>
              <h5>Rating and Reviews</h5>
              <div className="RatingsTop">
                <h1>
                  {resortListDetails?.resort_details?.resort_rating_data
                    ?.resort_rating_count !== 0
                    ? resortListDetails?.resort_details?.resort_rating_data?.average_rating?.toFixed(
                        1
                      )
                    : "No ratings yet"}
                </h1>
                <div className="RatingStar">
                  {resortListDetails?.resort_details?.resort_rating_data
                    ?.average_rating !== 0 ? (
                    Math.round(
                      resortListDetails?.resort_details?.resort_rating_data
                        ?.average_rating
                    ) === 5 ? (
                      <>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                      </>
                    ) : Math.round(
                        resortListDetails?.resort_details?.resort_rating_data
                          ?.average_rating
                      ) === 4 ? (
                      <>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star "></i>
                      </>
                    ) : Math.round(
                        resortListDetails?.resort_details?.resort_rating_data
                          ?.average_rating
                      ) === 3 ? (
                      <>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                      </>
                    ) : Math.round(
                        resortListDetails?.resort_details?.resort_rating_data
                          ?.average_rating
                      ) === 2 ? (
                      <>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                      </>
                    ) : Math.round(
                        resortListDetails?.resort_details?.resort_rating_data
                          ?.average_rating
                      ) === 1 ? (
                      <>
                        <i className="fa-solid fa-star checked"></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                      </>
                    ) : (
                      <>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                      </>
                    )
                  ) : (
                    <>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                      <i className="fa-solid fa-star "></i>
                    </>
                  )}
                </div>
                <p>
                  (
                  {resortListDetails?.resort_details?.resort_rating_data
                    ?.resort_rating_count !== 0
                    ? resortListDetails?.resort_details?.resort_rating_data
                        ?.resort_rating_count
                    : "0"}
                  )
                </p>
              </div>
            </aside>
            <aside style={{ width: "70%" }}>
              <div className="ProgressBarArea">
                <ul>
                  {resortListDetails?.resort_details?.resort_rating_data?.resort_rating?.find(
                    (item) => item?.star_rating === 5
                  ) ? (
                    <li>
                      <label>5</label>
                      <div className="ProgressBar">
                        <span
                          className="Progress"
                          style={{ width: "100%" }}
                        ></span>
                      </div>
                      <strong>250</strong>
                    </li>
                  ) : (
                    <li>
                      <label>5</label>
                      <div className="ProgressBar">
                        <span
                          className="Progress"
                          style={{ width: "0%" }}
                        ></span>
                      </div>
                      <strong>0</strong>
                    </li>
                  )}

                  <li>
                    <label>4</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "80%" }}
                      ></span>
                    </div>
                    <strong>120</strong>
                  </li>
                  <li>
                    <label>3</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "60%" }}
                      ></span>
                    </div>
                    <strong>20</strong>
                  </li>
                  <li>
                    <label>2</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "40%" }}
                      ></span>
                    </div>
                    <strong>10</strong>
                  </li>
                  <li>
                    <label>1</label>
                    <div className="ProgressBar">
                      <span
                        className="Progress"
                        style={{ width: "20%" }}
                      ></span>
                    </div>
                    <strong>0</strong>
                  </li>
                </ul>
              </div>
            </aside>
          </div>

          {resortListDetails?.resort_details?.resort_rating_data?.resort_rating?.map(
            (val, index) => (
              <div className="RatingsBox">
                <aside>
                  <div className="UserDetailsBox">
                    <h5>{val?.user_name}</h5>
                    <p>
                      {moment(new Date()).diff(
                        moment(val?.created_at),
                        "days"
                      ) === 0
                        ? "Today"
                        : moment(new Date()).diff(
                            moment(val?.created_at),
                            "days"
                          ) + " days ago"}
                    </p>
                    <span>
                      <img
                        style={{ height: "100%" }}
                        src={
                          val?.profile_image
                            ? val?.profile_image
                            : require("../../assets/images/profile-1.png")
                        }
                        alt="profile-1"
                      />
                    </span>
                  </div>
                  <div className="RatingStar">
                    {val?.star_rating !== 0 ? (
                      val?.star_rating === 5 ? (
                        <>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                        </>
                      ) : val?.star_rating === 4 ? (
                        <>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star "></i>
                        </>
                      ) : val?.star_rating === 3 ? (
                        <>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                        </>
                      ) : val?.star_rating === 2 ? (
                        <>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                        </>
                      ) : val.star_rating === 1 ? (
                        <>
                          <i className="fa-solid fa-star checked"></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                          <i className="fa-solid fa-star "></i>
                        </>
                      )
                    ) : (
                      <>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                        <i className="fa-solid fa-star "></i>
                      </>
                    )}
                  </div>
                </aside>
                <aside>
                  <p>{val?.comments}</p>
                </aside>
              </div>
            )
          )}

          {/* <div className="Paginations">
                        <ul>
                            <li><Link to="#" className="Grey"><i className="fa-solid fa-chevron-left"></i></Link></li>
                            <li><Link to="#">2</Link></li>
                            <li><Link to="#">3</Link></li>
                            <li><Link to="#">4</Link></li>
                            <li><Link to="#">5</Link></li>
                            <li><Link to="#" className="Black"><i className="fa-solid fa-chevron-right"></i></Link></li>
                        </ul>
                    </div> */}
        </div>

        {/* <div className="PinkWarning mt-5">
                    <p><img alt="danger" src={require("../../assets/images/danger.png")} />Check the latest COVID-19 restrictions before you travel. <span>Learn more</span></p>
                </div> */}

        {/* {addMoreList == true && countList >= 1 &&
                    <div className="ReserveBox" style={{display:"block"}}>
                        <a href="booking-details.html">I'll Reserve</Link>
                    </div>
                } */}
      </div>
    </FooterHeaderWrapper>
  );
};

export default ProductDetails;
