import FooterHeaderWrapper from "../layout/footerHeaderWrapper";
import { useLocation, Link, useNavigate } from "react-router-dom";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { sha256, sha224 } from 'js-sha256';
import axios from "axios";
import { generateSignature } from "./new";
import { config } from "../../constants";
import { formatDateWithAmPm } from "../../utils";

const initialState = {
    green_tax: '',
    goods_service_tax: '',
    service_fee: '',
    include_tax: 0,
    disable: false,
    errors: ''

}
const BookingDetails = () => {

    const [iState, updateState] = useState(initialState);
    const { green_tax, goods_service_tax, service_fee, include_tax, disable, errors } = iState;
    // let { package_id } = useParams();
    const Navigate = useNavigate();
    let Location = useLocation();
    let reserveData = Location?.state?.reserveData;
    let package_id = Location?.state?.package_id;
    let searchData = Location?.state?.searchData;


    const [arrivalTransferType, setArrivalTransferType] = useState("");
    const [arrivalTransferTypeId, setArrivalTransferTypeId] = useState(null);
    const [arrivalTransferCategory, setArrivalTransferCategory] = useState("");
    const [departureTransferType, setDepartureTransferType] = useState("");
    const [departureTransferId, setDepartureTransferId] = useState(null);
    const [departureTransferCategory, setDepartureTransferCategory] = useState("");
    let bookingList = useSelector((state) => state.booking.getPackageBookingList.data);

    const [supplementAmount, setSupplementAmount] = useState([]);
    const [supplementCategory, setSupplementCategory] = useState("");
    const [specialRequests, setSpecialRequests] = useState([]);
    const [otherRequest, setOtherRequest] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [grandTotal, setGrandTotal] = useState(0);
    const [bookingSuccess, setBookingSuccess] = useState(false);

    let user_details = JSON.parse(localStorage.getItem('user_details'));
    const [customerDetails, setCustomerDetails] = useState({
        first_name: user_details?.first_name,
        last_name: user_details?.last_name,
        email: user_details?.email,
        phone_number: user_details?.phone_number,
    });

    // const [data, setData] = useState(user_details);
    // const handleChange = (e) => {
    //     let { name, value } = e.target;
    //     setData({ ...data, [name]: value })
    // }

    console.log("from booking - ", user_details?.id);

    let packageRoomTypeDetails = useSelector((state) => state.packageReducer.packageRoomTypeDetails);
    useEffect(() => {
        if (reserveData && reserveData.length > 0) {
            let data = reserveData?.map((data) => data?.id)
            actions.getPackageRoomTypeDetailsAction({ data });
        }
    }, [reserveData]);


    //Handle booking
    // const handlePayment = async () => {
    //     if (user_details) {
    //         // const formData = new FormData();
    //         // formData.append('payment_amount', grandTotal);
    //         // formData.append('user_id', data?.id);
    //         // formData.append('resort_id', packageRoomTypeDetails?.data[0]?.resort_id);
    //         // formData.append('package_id', packageRoomTypeDetails?.data[0]?.package_id);
    //         // formData.append('booking_start_date', '2024-05-18');
    //         // formData.append('booking_type', 'package_booking');
    //         // formData.append('booking_end_date', '2024-05-21');
    //         // formData.append('rooms_adults', '2 room, 4 adults');
    //         // formData.append('room_type', 'deluxe');
    //         // formData.append('nights', 4);
    //         // formData.append('special_request', 'No');

    //         let data = packageRoomTypeDetails?.data?.map(item => {
    //             return {
    //                 resort_id: item?.resort_id,
    //                 package_id: item.package_id,
    //                 resort_room_id: item.id,
    //                 booking_type: "package_booking",
    //                 booking_start_date: moment(searchData?.checkInDate).format('YYYY-MM-DD'),
    //                 booking_end_date: moment(searchData?.checkOutDate).format('YYYY-MM-DD'),
    //                 rooms_adults: `${searchData?.adults} adults, ${searchData?.rooms} rooms`,
    //                 room: searchData?.rooms ? searchData?.rooms : 0,
    //                 adult: searchData?.adults ? searchData?.adults : 0,
    //                 children: searchData?.childrens ? searchData?.childrens : 0,
    //                 infant: searchData?.infant ? searchData?.infant : 0,
    //                 room_type: item.room_type1,
    //                 nights: moment(searchData?.checkOutDate).diff(moment(searchData?.checkInDate), 'days'),
    //                 payment_amount: totalGrandAmount,
    //                 special_request: JSON.stringify(specialRequests),
    //                 first_name: customerDetails?.first_name,
    //                 last_name: customerDetails?.last_name,
    //                 email: customerDetails?.email,
    //                 phone_number: customerDetails?.phone_number,
    //                 arrival_transfer_type_id: arrivalTransferTypeId,
    //                 departure_transfer_type_id: departureTransferId,
    //                 meal_supplement_id: JSON.stringify(supplementAmount?.map((data) => data?.id)),
    //                 status: 1
    //             };
    //         });

    //         // console.log('package booking detials ---- ', data);

    //         let res = await actions.createBookingAction(data)
    //         console.log('user found',res);
    //         if(res?.data?.status === true){
    //             toast.success(res?.data?.messege);
    //             setBookingSuccess(true);
    //             // Navigate('/booking-list');
    //         }else{
    //             toast.error(res?.data?.messege);
    //         }

    //     } else {
    //         setShowModal(true);
    //         toast.warning("Loigin or register for booking");
    //         console.log("user not found");
    //     }
    // }

    // const handlePayment = async () => {

    //     let data = {
    //         amount: totalGrandAmount * 100,
    //         currency: "MVR",
    //         localId: "my-invoice-123",
    //         redirectUrl: "https://shadescapesmv.com/",
    //         apiVersion: "2.0.0",
    //     }
    //     const result = await axios.post(`${config.PAYMENT_URL}/public/v2/transactions`, data, {
    //         headers:
    //         {
    //             Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6ImQyOWQwNjQ0LTNiYTItNGY2Yi1hYjZhLTJjYjY2ZTg2YzVkOSIsImNvbXBhbnlJZCI6IjYxYzM5ZmU3MjI0YzI0MDAwODgxNWNkYyIsImlhdCI6MTcyMzAyMzkxOCwiZXhwIjo0ODc4Njk3NTE4fQ.0pIiaiwAE0FSUjnjuR0FZ6diR3cEiNwIPgzlJ3oeU7o'
    //         },
    //     })
    //     if (result?.status == 201) {
    //         console.log(result?.data?.url, "url")
    //         window.open(result?.data?.url, '_main')
    //         // Navigate()
    //     }
    //     console.log("result", result);


    // }



    const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
    const [totalGrandAmount, setTotalGrandAmount] = useState(0);
    useEffect(() => {
        let total_discount_price = 0;
        let grand_total = 0;
        // let taxes_price = 100;

        updateState({ ...iState, include_tax: packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? '100' : '0' })
        let tranferAmount = arrivalTransferType ? parseInt(arrivalTransferType) : 0;
        let departureTransferAmount = departureTransferType ? parseInt(departureTransferType) : 0;
        const totalSuplementAmount = supplementAmount?.reduce((sum, item) => sum + parseInt(item.adult_price), 0);

        console.log({ packageRoomTypeDetails })

        packageRoomTypeDetails?.data?.map((priceData, index) => {
            // priceData?.package_room_pricing?.map((val) => (
            console.log(priceData?.data, "hhh")
            total_discount_price += priceData?.package_room_pricing?.at(0)?.base_discounted_price ? Number(priceData?.package_room_pricing?.at(0)?.base_discounted_price) : 0 * parseInt(reserveData ? reserveData[index]?.count : 1);
            grand_total += priceData?.package_room_pricing?.at(0)?.base_price_per_night ? Number(priceData?.package_room_pricing?.at(0)?.base_price_per_night) : 0 * parseInt(reserveData ? reserveData[index]?.count : 1);
            // if (val?.taxes == "include_taxes") {
            //     taxes_price = 100;
            // }
            // ))
        });


        console.log('grand total amount package booking details ---- ', grand_total);
        let calculated_total = grand_total - total_discount_price + tranferAmount + departureTransferAmount + totalSuplementAmount;

        console.log({ packageRoomTypeDetails })
        console.log({ reserveData })
        console.log({ totalDiscountPrice })
        console.log({ arrivalTransferType })
        console.log({ departureTransferAmount })
        console.log({ totalSuplementAmount })
        console.log({ calculated_total })



        setTotalGrandAmount(calculated_total);
        setTotalDiscountPrice(total_discount_price);

    }, [arrivalTransferType, supplementAmount, packageRoomTypeDetails, reserveData, departureTransferType]);


    const handleMealSupplement = (event, price, name, id) => {
        const { checked } = event.target;
        setSupplementAmount((prevState) => {
            if (checked) {
                return [...prevState, { meal_name: name, adult_price: price, id }];
            } else {
                return prevState.filter(item => item.meal_name !== name);
            }
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked, value } = e.target;

        if (checked) {
            setSpecialRequests(prevState => [...prevState, value]);
        } else {
            setSpecialRequests(prevState => prevState.filter(request => request !== value));
        }
    };

    const handleTextareaChange = (e) => {
        const { value } = e.target;
        setOtherRequest(value);
    };

    const handleOtherCheckboxChange = (e) => {
        const { checked } = e.target;

        if (checked) {
            setSpecialRequests(prevState => [...prevState, otherRequest]);
        } else {
            setSpecialRequests(prevState => prevState.filter(request => request !== otherRequest));
        }
    };

    const handleCustomerDetailsChange = (e) => {
        const { name, value } = e.target;
        setCustomerDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,

        }));
        updateState({ ...iState, errors: '' })
    };



    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!customerDetails?.first_name) {
            error.first_nameError = '*First Name is required';
            formIsValid = false;
        }
        if (!customerDetails?.last_name) {
            error.last_nameError = '*Last Name is required';
            formIsValid = false;
        }
        if (!customerDetails?.email) {
            error.emailError = '*Email is mandetory';
            formIsValid = false;
        }
        if (!customerDetails?.phone_number) {
            error.phone_numberError = '*Phone Number is mandetory';
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }


    const scrollHandler = (name) => {
        const section = document.getElementById(name);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }

    };



    const handlePayment = async () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            updateState({ ...iState, disable: true });
            let data = {
                amount: (
                    Number.parseFloat(
                        Number(totalGrandAmount) +
                        (Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0)) +
                        (Number((green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1)))) +
                        (Number(
                            (totalGrandAmount + Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0) + (green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1))
                            ) * goods_service_tax / 100)) +
                        (
                            Number(
                                Number(totalGrandAmount) +
                                (Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0)) +
                                (Number((green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1)))) +
                                (Number(
                                    (totalGrandAmount + Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0) + (green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1))
                                    ) * goods_service_tax / 100))

                            )
                            * service_fee / 100
                        ))?.toFixed(2)) * 100,
                currency: "MVR",
                localId: "my-invoice-123",
                redirectUrl: process.env.NODE_ENV === "development" ? "https://0b53-103-47-13-244.ngrok-free.app/booking-details" : "https://shadescapesmv.com/",
                apiVersion: "2.0.0",
            };
            try {
                const result = await axios.post(`${config.API_BASE_URL}/api/create-payments`, data);
                console.log({ result })
                if (result?.status === 200) {
                    updateState({ ...iState, disable: false });
                    window.open(result?.data?.data?.url, '_main');
                } else {
                    console.error('Payment failed');
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        }
        else {
            scrollHandler('Customer_Detail')
        }
    };

    const taxList = async () => {
        const result = await axios.get(`${config.API_BASE_URL}/api/admin/tax`);
        console.log({ result })
        if (result?.status === 200) {
            updateState({
                ...iState,
                green_tax: result?.data?.data?.find((item) => item?.tax_type == 'Green Tax')?.amount?.split("%")?.at(0),
                goods_service_tax: result?.data?.data?.find((item) => item?.tax_type == 'Goods & Services Tax')?.amount?.split("%")?.at(0),
                service_fee: result?.data?.data?.find((item) => item?.tax_type == 'Service Fee')?.amount?.split("%")?.at(0),
            })
        }
    }

    const getTransactionList = async () => {
        if (window?.location?.href?.includes('transactionId')) {
            let s1 = window?.location?.href.split('?');
            let s2 = s1?.at(1)?.split("&");
            let s3 = s2?.at(0)?.split("=")?.at(1);


            if (s2?.at(1) == "state=CONFIRMED") {
                const result = await axios.get(`${config.API_BASE_URL}/api/get-status/${s3}`);
                console.log({ result })
                if (result?.status === 200) {
                    toast.success("Payment Successfully", {
                        position: 'top-right',
                        autoClose: 3000
                    })
                    let data = packageRoomTypeDetails?.data?.map(item => {
                        return {
                            resort_id: item?.resort_id,
                            package_id: item.package_id,
                            resort_room_id: item.id,
                            booking_type: "package_booking",
                            booking_start_date: moment(searchData?.checkInDate).format('YYYY-MM-DD'),
                            booking_end_date: moment(searchData?.checkOutDate).format('YYYY-MM-DD'),
                            rooms_adults: `${searchData?.adults} adults, ${searchData?.rooms} rooms`,
                            room: searchData?.rooms ? searchData?.rooms : 0,
                            adult: searchData?.adults ? searchData?.adults : 0,
                            children: searchData?.childrens ? searchData?.childrens : 0,
                            infant: searchData?.infant ? searchData?.infant : 0,
                            room_type: item.room_type1,
                            nights: moment(searchData?.checkOutDate).diff(moment(searchData?.checkInDate), 'days'),
                            payment_amount: totalGrandAmount,
                            special_request: JSON.stringify(specialRequests),
                            first_name: customerDetails?.first_name,
                            last_name: customerDetails?.last_name,
                            email: customerDetails?.email,
                            phone_number: customerDetails?.phone_number,
                            arrival_transfer_type_id: arrivalTransferTypeId,
                            departure_transfer_type_id: departureTransferId,
                            meal_supplement_id: JSON.stringify(supplementAmount?.map((data) => data?.id)),
                            status: 1
                        };
                    });
                    let res = await actions.createBookingAction(data)
                    console.log('user found', res);
                    if (res?.data?.status === true) {
                        toast.success(res?.data?.messege);
                        setBookingSuccess(true);
                        if (bookingList?.find((item) => item?.id == res?.data?.data?.at(0)?.id)) {
                            const data = {
                                user_id: user_details?.id,
                                booking_id: res?.data?.data?.at(0)?.id,
                                transaction_id: s3,
                                payment_amount: result?.data?.data?.amount / 100,
                                payment_date: result?.data?.data?.created?.split("T")?.at(0),
                                payment_time: formatDateWithAmPm(result?.data?.data?.created?.split("T")?.at(0)),
                                payment_method: 'debit card',
                                user_name: `${user_details?.first_name} ${user_details?.last_name}`,
                                phone_number: user_details?.phone_number,
                                receipt: '',
                            }
                            try {
                                const addPayResult = await axios.post(`${config.API_BASE_URL}/api/add-pay`, data);
                                console.log({ addPayResult })
                            } catch (error) {
                                console.error('An error occurred:', error);
                            }
                        }
                    } else {
                        toast.error(res?.data?.messege);
                    }

                } else {
                    console.error('Payment failed');
                }
            }
        }
    }


    useEffect(() => {
        taxList();
    }, [])


    useEffect(() => {
        getTransactionList();
    }, [window?.location?.href?.includes('transactionId')]);

    // useEffect(() => {
    //     if (green_tax && service_fee && goods_service_tax) {
    //         console.log({ green_tax, service_fee, goods_service_tax })
    //     }
    // }, [green_tax, service_fee, goods_service_tax])


    console.log({ totalGrandAmount, packageRoomTypeDetails, reserveData });

    console.log("dsfgngfdsa")



    const successBooking = () => {
        Navigate('/booking-list');
    }


    console.log({ searchData })
    return (
        <FooterHeaderWrapper modalStatus={showModal}>
            <div className="DetailsGrey">

                <div className="HotelBreadcumb">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/packages">Packages</Link></li>
                        <li><Link to={`/package-details/${package_id}`}>Package Details</Link></li>
                        <li className="active">Package Booking Details</li>
                    </ul>
                </div>

                <div className="row">
                    <div className="col-lg-8 col-md-7">
                        {packageRoomTypeDetails?.data?.map((data, index) => {
                            return (
                                <>
                                    <div className="SingleRoomBox" key={index}>
                                        <figure><img src={data?.images ? data?.images : require('../../assets/images/r2.jpeg')} alt="single-room" /></figure>
                                        <div className="SingleRoomRight">
                                            <h5>{data?.room_type1 ? data?.room_type1 : data?.room_type2}</h5>
                                            <ul className="First">
                                                <li><span><img src={require("../../assets/images/sq-ft.png")} alt="measurement" /></span>300 sq ft</li>
                                                <li><span><img src={require("../../assets/images/sq-ft.png")} alt="measurement" /></span>King Size Bed</li>
                                                <li><span><img src={require("../../assets/images/sq-ft.png")} alt="measurement" /></span>City View</li>
                                            </ul>
                                            <ul className="Second">
                                                {
                                                    packageRoomTypeDetails?.package_meal_supplements && packageRoomTypeDetails?.package_meal_supplements?.map((val, mealIndex) => (
                                                        <li key={mealIndex}><i className="fa-solid fa-check"></i>{val?.meal_plan}</li>
                                                    ))
                                                }
                                                {
                                                    packageRoomTypeDetails?.unique_amenities && packageRoomTypeDetails?.unique_amenities.map((val, amnIndex) => (
                                                        <li key={amnIndex}><i className="fa-solid fa-check"></i>{val}</li>
                                                    ))
                                                }
                                                {/* <li><i className="fa-solid fa-check"></i>Bed and Breakfast</li>
                                                <li><i className="fa-solid fa-check"></i>Express check-in, Free WiFi, Free fitness center access</li> */}
                                            </ul>
                                            <p><span><img src={require("../../assets/images/icon-3.png")} alt="booking" /></span>{reserveData && reserveData[index]?.count ? reserveData[index]?.count * 2 : 1} adult X {reserveData && reserveData[index]?.count ? reserveData[index]?.count : 1} room</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                        {/* <div className="SingleRoomBox">
                            <figure><img src="/assets/images/room-2.png" /></figure>
                            <div className="SingleRoomRight">
                                <h5>Bedroom Water Retreat (with Slide)</h5>
                                <ul className="First">
                                    <li><span><img src="/assets/images/sq-ft.png" /></span>300 sq ft</li>
                                    <li><span><img src="/assets/images/sq-ft.png" /></span>King Size Bed</li>
                                    <li><span><img src="/assets/images/sq-ft.png" /></span>City View</li>
                                </ul>
                                <ul className="Second">
                                    <li><i className="fa-solid fa-check"></i>Bed and Breakfast</li>
                                    <li><i className="fa-solid fa-check"></i>Express check-in, Free WiFi, Free fitness center access</li>
                                </ul>
                                <p><span><img src="/assets/images/icon-3.png" /></span>2 adult X 1 room</p>
                            </div>
                        </div> */}


                        <div className="AmenitiesBox">
                            <h4>Amenities</h4>
                            <ul>
                                {
                                    packageRoomTypeDetails?.package_meal_supplements && packageRoomTypeDetails?.package_meal_supplements?.map((val, mealIndex) => (
                                        <li key={mealIndex}>{val?.meal_plan}</li>
                                    ))
                                }
                                {
                                    packageRoomTypeDetails?.unique_amenities && packageRoomTypeDetails?.unique_amenities.map((val, amnIndex) => (
                                        <li key={amnIndex}>{val}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="ArrivalTransferType">
                                    <h4>Arrival Transfer Type</h4>
                                    <span className="Carbon"><img src={require("../../assets/images/carbon_information.png")} alt="transfer" /></span>
                                    <ul>
                                        {
                                            packageRoomTypeDetails?.package_transfer_types?.map((data, index) => (
                                                data?.trip_type == "Arrival" ?
                                                    <li key={index}>
                                                        <label className="Radio"> {data?.transfer_type}
                                                            <input type="radio" name="arrival" onChange={() => { setArrivalTransferType(data?.adult_price); setArrivalTransferCategory(data?.transfer_type); setArrivalTransferTypeId(data?.id) }} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <h6>${data?.adult_price}</h6>
                                                    </li>
                                                    : ""
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="ArrivalTransferType">
                                    <h4>Departure Transfer Type</h4>
                                    <span className="Carbon"><img src={require("../../assets/images/carbon_information.png")} alt="information" /></span>
                                    <ul>
                                        {
                                            packageRoomTypeDetails?.package_transfer_types?.map((data, index) => (
                                                data?.trip_type == "Departure" ?
                                                    <li key={index}>
                                                        <label className="Radio"> {data?.transfer_type}
                                                            <input type="radio" name="departure" onChange={() => { setDepartureTransferType(data?.adult_price); setDepartureTransferCategory(data?.transfer_type); setDepartureTransferId(data?.id) }} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <h6>${data?.adult_price}</h6>
                                                    </li>
                                                    : ""
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ArrivalTransferType">
                                    <h4>Add Supplement</h4>
                                    <span className="Carbon"><img src={require("../../assets/images/carbon_information.png")} alt="info" /></span>
                                    <ul>
                                        {
                                            packageRoomTypeDetails?.package_meal_supplements?.map((val, index) => (
                                                val?.resort_meal_supplement?.map((value) => (
                                                    <li key={index}>
                                                        <label className="CheckBox"> {val?.meal_plan}
                                                            <input
                                                                type="checkbox"
                                                                name="extra_supplement"
                                                                onChange={(e) => { handleMealSupplement(e, value?.adult_price, val?.meal_plan, val?.id) }} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <h6>${value?.adult_price}</h6>
                                                    </li>
                                                ))

                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="SpecialRequest">
                            <h4>Special Request</h4>
                            <div className="SpecialContent">
                                <h3>We'll make sure your property request quickly.</h3>
                                <div className="form-group">
                                    <label className="CheckBox">
                                        I'd like a room on a high floor
                                        <input
                                            type="checkbox"
                                            name="highFloor"
                                            value="I'd like a room on a high floor"
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="CheckBox">
                                        I’d like a quiet room
                                        <input
                                            type="checkbox"
                                            name="quietRoom"
                                            value="I’d like a quiet room"
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="CheckBox">
                                        I’d like an adjoining room
                                        <input
                                            type="checkbox"
                                            name="adjoiningRoom"
                                            value="I’d like an adjoining room"
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="CheckBox">
                                        I’d like to have a baby cot (additional charges may apply)
                                        <input
                                            type="checkbox"
                                            name="babyCot"
                                            value="I’d like to have a baby cot (additional charges may apply)"
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="CheckBox">
                                        Other
                                        <input
                                            type="checkbox"
                                            name="other"
                                            onChange={handleOtherCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="form-group" id="Customer_Detail">
                                    <h6>Additional notes</h6>
                                    <textarea
                                        className="form-control"
                                        placeholder="Write here"
                                        rows="4"
                                        value={otherRequest}
                                        onChange={handleTextareaChange}
                                        onBlur={handleOtherCheckboxChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="CustomerDetails" >
                            <h4>Customer Details</h4>
                            <div className="CustomerContent">
                                <form>
                                    <div className="row">
                                        <div className="form-group col-lg-2 col-md-4">
                                            <h6>Last Name</h6>
                                            <div className="PhoneSearchArea">
                                                <select className="form-control">
                                                    <option selected>Mr.</option>
                                                    <option>Miss.</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="form-group col-lg-5 col-md-8">
                                            <h6>First Name</h6>
                                            <input type="text"
                                                name="first_name"
                                                value={customerDetails?.first_name ? customerDetails?.first_name : ""}
                                                onChange={handleCustomerDetailsChange}
                                                className="form-control"
                                                placeholder="Enter First Name" />
                                            <p className="Error" style={{ color: 'red' }}>{errors?.first_nameError}</p>
                                        </div>
                                        <div className="form-group col-lg-5 col-md-12">
                                            <h6>Last Name</h6>
                                            <input
                                                type="text"
                                                name="last_name"
                                                value={customerDetails?.last_name ? customerDetails?.last_name : ""}
                                                onChange={handleCustomerDetailsChange}
                                                className="form-control"
                                                placeholder="Enter Last Name" />
                                            <p className="Error" style={{ color: 'red' }}>{errors?.last_nameError}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <h6>Mobile Number</h6>
                                        <div className="PhoneSearchArea">
                                            <input type="text" className="form-control SmallInput" placeholder="+91" />
                                            <input type="text"
                                                name="phone_number"
                                                value={customerDetails?.phone_number ? customerDetails?.phone_number : ""}
                                                onChange={handleCustomerDetailsChange}
                                                className="form-control"
                                                placeholder="Enter Phone Number" />
                                            <p className="Error" style={{ color: 'red' }}>{errors?.phone_numberError}</p>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <h6>Email</h6>
                                        <input type="text"
                                            name="email"
                                            value={customerDetails?.email ? customerDetails?.email : ""}
                                            onChange={handleCustomerDetailsChange}
                                            className="form-control"
                                            placeholder="Enter Email" />
                                        <p className="Error" style={{ color: 'red' }}>{errors?.emailError}</p>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div className="MultiFacilities" id="Policies">
                            <div className="PolicyGreyBox">
                                <h4>Policies</h4>
                            </div>
                            <div className="PoliciesBox Common">
                                <div className="d-flex">
                                    <aside>
                                        <p>Bookings at this property are non-refundable.</p>

                                        <p className="mt-4">Learn more about our <Link href="#">Cancellation policies <span><img src={require("../../assets/images/carbon_information.png")} alt="img" /></span></Link></p>
                                    </aside>
                                    <aside>
                                        <p>If you have upcoming trips, you can manage or <br /> cancel your booking in your traveler account.</p>
                                    </aside>
                                </div>
                            </div>
                            <div className="CheckInCheckOut Common">
                                <h5>Check-in/Check-out</h5>
                                <ul>
                                    <li><span><img src={require("../../assets/images/check-in.png")} alt="checkin" /></span>Check in after: 4:00 PM</li>
                                    <li><span><img src={require("../../assets/images/check-out.png")} alt="checkin" /></span>Check out before: 9:00 AM</li>
                                </ul>
                            </div>
                            <div className="DamageIncidentals Common">
                                <h5>Damage and Incidentals</h5>
                                <ul>
                                    <li>You will be responsible for any damage to the rental property caused by you or your party during your stay.</li>
                                </ul>
                            </div>
                            <div className="HouseRules Common">
                                <h5>House Rules</h5>
                                <ul>
                                    <li>Children allowed No Pets.</li>
                                    <li>No Events</li>
                                    <li>Max Guess:12</li>
                                    <li>No Pets</li>
                                    <li>No Smoking</li>
                                    <li>Minimum Age of Primary Renter: 25</li>
                                </ul>
                            </div>
                            <div className="GettingAround Common">
                                <aside>
                                    <h5>Getting around</h5>
                                    <ul>
                                        <li>Airport transfer fee: 1100 USD</li>
                                        <li>Travel time to airport (minutes): 10</li>
                                    </ul>
                                </aside>
                                <aside>
                                    <h5>Extra</h5>
                                    <ul>
                                        <li>
                                            Breakfast charge (unless included in room price): 80 USD
                                        </li>
                                        <li>Daily Internet/Wi-Fi fee: 0 USD</li>
                                    </ul>
                                </aside>
                            </div>
                            <div className="DamageIncidentals Common">
                                <h5>Others</h5>
                                <ul>
                                    <li>Additional child rates are applicable to children 7-14 years old.</li>
                                    <li>Children 15 years and over are subject to additional adult rates.</li>
                                    <li>The additional charges are applicable to all services including meals and air transfer fees.</li>
                                    <li>When booking more than 5 rooms, different policies and additional supplements may apply.</li>
                                </ul>
                            </div>
                            <div className="DamageIncidentals Common">
                                <h5>The Property</h5>
                                <ul>
                                    <li>Number of bars/lounges: 2</li>
                                    <li>Number of restaurants: 3</li>
                                    <li>Number of rooms : 54</li>
                                    <li>Room voltage: 220</li>
                                    <li>Year property opened: 2016</li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="NonRefundableBox">
                            <figure>
                                {packageRoomTypeDetails?.data?.map((imageUrl, index) => {
                                    if (index === 0) {
                                        return (
                                            <img key={index} alt={`room-details-${index}`} src={imageUrl?.images ? imageUrl?.images : imageUrl?.package_image} width="100%" />
                                        );
                                    } else {
                                        return null; // or any other placeholder for other indices
                                    }
                                })}
                                {/* <img alt="room-details" src={packageRoomTypeDetails[0]?.data?.images} width="100%" /> */}
                            </figure>
                            <figcaption>
                                {
                                    packageRoomTypeDetails?.data?.map((val, index) => {
                                        if (index === 0) {
                                            return (
                                                <>
                                                    <h1>{val?.package_name}</h1>
                                                    <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                                    <p><i className="fa-solid fa-location-dot"></i>{val?.resort_address}, {val?.resort_pincode}</p>
                                                    <div className="Cancellation">
                                                        {val?.resort_refundable ? <label>Refundable</label> : <label>Non refundable</label>}
                                                        <span><img alt="carbon-info" src={require("../../assets/images/carbon_information.png")} />Cancellation Policy</span>
                                                    </div>
                                                </>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })
                                }

                                <label>Check in: {moment(searchData?.checkInDate).format('dddd, MMMM D, YYYY')}</label>
                                <label>Check out: {moment(searchData?.checkOutDate).format('dddd, MMMM D, YYYY')}</label>
                                <label>{moment(searchData?.checkOutDate).diff(moment(searchData?.checkInDate), 'days')} days</label>
                            </figcaption>
                        </div>
                        <div className="PriceDetailsBox">
                            <h4>Price Details</h4>
                            <div className="PriceDetailsContent">
                                <table>
                                    <tbody>
                                        {/* {packageRoomTypeDetails?.data?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{reserveData ? reserveData[index]?.count : 1}  rooms X {reserveData ? reserveData[index]?.count * 2 : 1} nights</td>
                                                <td>$
                                                    {parseInt(packageRoomTypeDetails?.package_pricing[0]?.package_pricing_data_range[0]?.base_price_per_night) * parseInt(reserveData ? reserveData[index]?.count : 1)}
                                                </td>
                                            </tr>
                                        )
                                        )} */}

                                        {
                                            packageRoomTypeDetails?.data?.map((priceData, index) => (
                                                // priceData?.package_room_pricing?.map((val) => (
                                                <tr key={index}>
                                                    <td>{reserveData ? reserveData[index]?.count : 1}  rooms X {reserveData ? reserveData[index]?.count * 2 : 1} nights</td>

                                                    <td>$
                                                        {priceData?.package_room_pricing[0]?.base_price_per_night ? priceData?.package_room_pricing[0]?.base_price_per_night : 0 * parseInt(reserveData ? reserveData[index]?.count : 1)}
                                                    </td>

                                                </tr>
                                                // ))
                                            ))
                                        }
                                        <tr>
                                            <td>Discount</td>
                                            <td>
                                                -${totalDiscountPrice}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tax and service fees</td>
                                            <td>${packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0}</td>
                                        </tr>
                                        <tr>
                                            <td>Green Tax(Per Guest){` ($${green_tax})`}</td>

                                            <td>${Number.parseFloat((green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1)))?.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td>Goods & Services Tax{` (${goods_service_tax}%)`}</td>
                                            <td>${Number.parseFloat(
                                                (totalGrandAmount + Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0) + (green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1))
                                                ) * goods_service_tax / 100)?.toFixed(2)}</td>
                                            {/* <td>${Number.parseFloat(totalGrandAmount * goods_service_tax / 100)?.toFixed(3)}</td> */}
                                        </tr>
                                        <tr>
                                            <td>Service Fee{` (${service_fee}%)`}</td>
                                            <td>$
                                                {Number.parseFloat(
                                                    (
                                                        Number(totalGrandAmount) +
                                                        (Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0)) +
                                                        (Number((green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1)))) +
                                                        (Number(
                                                            (totalGrandAmount + Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0) + (green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1))
                                                            ) * goods_service_tax / 100))

                                                    )
                                                    * service_fee / 100
                                                )?.toFixed(2)}
                                            </td>
                                            {/* <td>${Number.parseFloat(totalGrandAmount * service_fee / 100)?.toFixed(3)}</td> */}
                                        </tr>
                                        {arrivalTransferType?.trim() !== "" &&
                                            <tr>
                                                <td>Arrival Transfer Type ({arrivalTransferCategory})</td>
                                                <td>${arrivalTransferType}</td>
                                            </tr>
                                        }
                                        {
                                            supplementAmount && supplementAmount?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>Food Supplement ({data?.meal_name})</td>
                                                    <td>${data?.adult_price}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total</td>
                                            <td>$
                                                {Number.parseFloat(
                                                    Number(totalGrandAmount) +
                                                    (Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0)) +
                                                    (Number((green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1)))) +
                                                    (Number(
                                                        (totalGrandAmount + Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0) + (green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1))
                                                        ) * goods_service_tax / 100)) +
                                                    (
                                                        Number(
                                                            Number(totalGrandAmount) +
                                                            (Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0)) +
                                                            (Number((green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1)))) +
                                                            (Number(
                                                                (totalGrandAmount + Number(packageRoomTypeDetails?.package_pricing?.at(0)?.taxes == "include_taxes" ? 100 : 0) + (green_tax * (reserveData && reserveData?.at(0)?.count ? reserveData?.at(0)?.count * 2 : 1))
                                                                ) * goods_service_tax / 100))

                                                        )
                                                        * service_fee / 100
                                                    )




                                                )?.toFixed(2)}

                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <p><span>Note:</span> Included in price: Green Tax Per Guest Per Night Rs. 1,294.56, Tax 29.92% Not included in price : Roundtrip Sea Plane Transfer Fee (Pay at the property) USD 2,534.00</p>
                                <div className="p-3">
                                    <button className="PayBtn" data-toggle="modal" style={{ backgroundColor: disable ? '#000000d9' : 'rgba(0, 0, 0, 1)' }} data-target="#CongratulationsModal" onClick={handlePayment} disabled={disable}>Pay</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="PinkWarning mt-5">
                    <p><img alt="danger" src={require("../../assets/images/danger.png")} />Check the latest COVID-19 restrictions before you travel. <span>Learn more</span></p>
                </div> */}
            </div>

            <Modal show={bookingSuccess} className="ModalBox">
                <Modal.Body>
                    <div className="FormArea Congratulatios">
                        <Link to="#" className="CloseModal" data-dismiss="modal" onClick={() => setBookingSuccess(false)} >×</Link>
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="modal-title">
                                        <Link to="#"><img src={require("../../assets/images/congratulation.png")} alt="congrats" /></Link>
                                        <h2>Congratulations</h2>
                                        <p>Your Booking is confirmed</p>
                                        {/* <p className="BookingId">Your Booking ID <span>#723097557</span></p> */}
                                    </div>
                                </div>
                                <span onClick={successBooking} className="FormButton" style={{ cursor: 'pointer' }}>Go to My Bookings</span>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

        </FooterHeaderWrapper>
    )
}

export default BookingDetails;